import {styled} from '@mui/material/styles';
import {IconButton} from '@mui/material';

export const StyledIconButton = styled(IconButton)(({theme}) => ({
  padding: '4px',
  borderRadius: '8px',
  color: theme.palette.basic[800],
  '&:hover': {
    color: theme.palette.basic[800],
    background: theme.palette.basic[50],
  },
  '&:focus': {
    color: theme.palette.basic[800],
    background: theme.palette.basic[200],
  },
  '&:disabled': {
    color: theme.palette.basic[300],
  },
}));
