import {fetchBaseQuery} from '@reduxjs/toolkit/query';

export const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      // TODO: get and then set token when server'll be ready

      // const token = localStorage.getItem('token');

      // if (token) {
      //   headers.set('authorization', `Bearer ${token}`);
      // }

      return headers;
    },
  });
