import dayjs from 'dayjs';

import {Icon} from '../../../components';
import type {ClinicalTrial} from '../../../api/service-types';
import {NavItemType, ClinicalTrialsByStatus} from '../../../interfaces/navigation';
import EventIcon from '@mui/icons-material/Event';

const iconMain = (name: string) => <Icon icon={name} size={20} />;

export const getNavigationTree = (clinicalTrialsByStatus: ClinicalTrialsByStatus): NavItemType[] => {
  const hasClinicalTrialsEnded = clinicalTrialsByStatus.ended.length > 0;

  const navigationTree = [
    {
      title: 'Dashboard',
      icon: iconMain('dashboard'),
      children: [...clinicalTrialsByStatus.active],
    },
    {
      title: 'Participants',
      path: '/dashboard/participants',
      icon: iconMain('participants'),
    },
    {
      title: 'Data tables',
      path: '/dashboard/data-tables',
      icon: iconMain('data'),
    },
    {
      title: 'Events',
      path: '/dashboard/events',
      icon: <EventIcon />,
    },
    {
      title: 'Settings',
      path: '/dashboard/settings',
      icon: iconMain('settings'),
    },
  ];

  if (hasClinicalTrialsEnded) {
    navigationTree[0].children?.push({
      title: 'ended trials',
      children: [...clinicalTrialsByStatus.ended],
    });
  }

  return navigationTree;
};

export const getClinicalTrialsByStatus = (clinicalTrials: ClinicalTrial[]) => {
  const currentDate = dayjs();

  return clinicalTrials.reduce<ClinicalTrialsByStatus>(
    (acc, trial) => {
      const nextTrial = {
        title: trial.title,
        icon: <Icon icon="document" size={16} />,
        path: `/dashboard/${trial.id}`,
      };
      const endDateTime = dayjs(trial.endDateTime);
      const diff = endDateTime.diff(currentDate);
      if (diff >= 0) acc.active.push(nextTrial);
      if (diff < 0) acc.ended.push(nextTrial);
      return acc;
    },
    {active: [], ended: []},
  );
};
