import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')(() => ({
  position: 'relative',
}));

export const StyledDropzoneContainer = styled('div')(() => ({
  position: 'relative',
  width: '100px',
  height: '100px',
  display: 'flex',
}));

export const StyledIconEditWrapper = styled('div')(({theme}) => ({
  padding: '7px',
  border: `2px solid ${theme.palette.primaryCustom[800]}`,
  display: 'flex',
  borderRadius: '50%',
  position: 'absolute',
  bottom: '0px',
  right: '0px',
  backgroundColor: theme.palette.basic[0],
  cursor: 'pointer',
}));

export const StyledIconResetWrapper = styled('div')(({theme}) => ({
  padding: '7px',
  border: `2px solid ${theme.palette.primaryCustom[800]}`,
  display: 'flex',
  borderRadius: '50%',
  position: 'absolute',
  top: '0px',
  right: '0px',
  backgroundColor: theme.palette.basic[0],
}));
