import {Checkbox, Divider, Popover, Select, SelectProps, Typography} from '@mui/material';
import React, {useMemo, useState, useEffect} from 'react';

import {Icon, Search} from '../../../components';
import {usePopover, useSearch} from '../../../hooks';

import {
  StyledClearButton,
  StyledFormControl,
  StyledInputLabel,
  StyledMenuItem,
  StyledOutlinedInput,
  StyledPopoverContent,
} from './styles';

export type MultiSelectProps<OptionT = any> = Omit<SelectProps<OptionT[]>, 'value' | 'onChange'> & {
  value: OptionT[];
  onChange: (value: OptionT[]) => void;
  options: OptionT[];
  placeholder?: string;
  width?: string | number;
  searchKeys?: string[];
  keyExtractor: (option: OptionT) => string;
  renderOption: (option: OptionT, isSelected: boolean) => React.ReactNode;
};

export const MultiSelect = ({
  value = [],
  onChange,
  options,
  keyExtractor,
  renderOption,
  placeholder,
  searchKeys,
  width = '100%',
}: MultiSelectProps) => {
  const [selected, setSelected] = useState<typeof value>(value);
  const {open, handleOpen, handleClose} = usePopover();
  const {searchValue, onChangeSearchValue, onResetSearchValue} = useSearch();

  const defaultSearchKeys = searchKeys ? searchKeys : Object.keys(options[0]);

  const onAddSelected = (option: (typeof options)[0]) => {
    setSelected(prev => {
      const nextSelected = [...prev, option];
      onChange(nextSelected);
      return nextSelected;
    });
  };

  const onRemoveSelected = (option: (typeof options)[0]) => {
    setSelected(prev => {
      const nextSelected = prev.filter(selectedItem => keyExtractor(selectedItem) !== keyExtractor(option));
      onChange(nextSelected);
      return nextSelected;
    });
  };

  const onReset = () => {
    onChange([]);
    setSelected([]);
    onResetSearchValue();
  };

  const notSelectedOptions = useMemo(
    () =>
      options?.filter(option => {
        const valueFilter = !value?.some(valueItem => {
          return keyExtractor(valueItem) === keyExtractor(option);
        });

        const searchFilter = searchValue
          ? defaultSearchKeys.some(key => {
              if (typeof option[key] !== 'string') return true;
              return option[key].toLowerCase().includes(searchValue.toLowerCase());
            })
          : true;

        return valueFilter && searchFilter;
      }),
    [value, options, searchValue],
  );

  const hasSelected = selected.length > 0;

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <StyledFormControl sx={{width}}>
      {placeholder && (
        <StyledInputLabel id="multiple-checkbox-label" hasSelected={hasSelected} onClick={handleOpen}>
          <Typography variant="bodyBig">{placeholder}</Typography>
        </StyledInputLabel>
      )}
      <Select
        id="multiple-checkbox"
        labelId="multiple-checkbox-label"
        multiple
        open={Boolean(open)}
        onOpen={handleOpen}
        sx={{px: '0px', py: '6px'}}
        value={selected}
        input={<StyledOutlinedInput hasSelected={hasSelected} />}
        IconComponent={({className}) => (
          <Icon
            icon="down"
            size="16px"
            className={`${className} ${Boolean(open) ? 'MuiSelect-iconOpen' : ''}`}
            style={{zIndex: 1}}
          />
        )}
        renderValue={selected => <Typography variant="bodyBig">{`${placeholder}: ${selected.length}`}</Typography>}
      />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}>
        <StyledPopoverContent>
          <Search value={searchValue} onChange={onChangeSearchValue} onReset={onResetSearchValue} />
          {selected?.map(valueItem => {
            const key = keyExtractor(valueItem);

            return (
              <StyledMenuItem
                key={key}
                value={valueItem as string}
                onClick={() => {
                  onRemoveSelected(valueItem);
                }}>
                <Checkbox checked />
                {renderOption(valueItem, true)}
              </StyledMenuItem>
            );
          })}

          {hasSelected && (
            <>
              <Divider />
              <StyledClearButton onClick={onReset}>
                <Icon icon="close" size="20px" />
                <Typography variant="captionBig">Clear all</Typography>
              </StyledClearButton>
            </>
          )}
          {notSelectedOptions?.map(optionItem => {
            const key = keyExtractor(optionItem);

            return (
              <StyledMenuItem
                key={key}
                value={optionItem as string}
                onClick={() => {
                  onAddSelected(optionItem);
                }}>
                <Checkbox />
                {renderOption(optionItem, false)}
              </StyledMenuItem>
            );
          })}
        </StyledPopoverContent>
      </Popover>
    </StyledFormControl>
  );
};
