import {useTheme} from '@mui/material';

import {Color} from '../../interfaces/styles';
import {StyledContainer} from './styles';

export type TagStatus = 'active' | 'ended' | 'new' | 'in-progress' | 'completed' | 'n/a';

export type TagProps = {
  marginval?: string;
  status: TagStatus | string;
};

export const Tag = ({marginval, status}: TagProps): JSX.Element => {
  const theme = useTheme();

  const tagBackgroundMap: Record<TagStatus | string, Color> = {
    active: theme.palette.primaryCustom[500],
    ended: theme.palette.basic[600],
    new: theme.palette.customColors.darkBlue,
    'in-progress': theme.palette.customColors.pacificBlue,
    completed: theme.palette.customColors.green,
    'n/a': theme.palette.customColors.darkGrey,
  };

  return (
    <StyledContainer marginval={marginval || ''} background={tagBackgroundMap[status]}>
      {status}
    </StyledContainer>
  );
};
