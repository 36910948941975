import {Container, Typography} from '@mui/material';

import {ForgotPasswordForm, SuccessfulEmail} from '../../../sections';
import {useBoolean} from '../../../hooks';

import {StyledContent} from './styles';

export const ForgotPasswordPage = () => {
  const {
    value: isEmailSentSuccessfully,
    setTrue: sentEmailSuccessfully,
    setFalse: sentEmailUnsuccessfully,
  } = useBoolean();

  return (
    <Container maxWidth="sm">
      {isEmailSentSuccessfully ? (
        <SuccessfulEmail sentEmailUnsuccessfully={sentEmailUnsuccessfully} />
      ) : (
        <StyledContent>
          <Typography variant="h1" gutterBottom>
            Forgot password?
          </Typography>
          <Typography variant="bodyBig" gutterBottom>
            Enter the email address and we’ll send you instructions to reset your password
          </Typography>

          <ForgotPasswordForm sentEmailSuccessfully={sentEmailSuccessfully} />
        </StyledContent>
      )}
    </Container>
  );
};
