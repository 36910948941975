import {IconButton, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({theme}) => ({
  display: 'flex',
  minHeight: '112px',
  width: '100%',
  padding: '12px 16px 36px 12px',
  marginTop: '10px',
  gap: '12px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.basic[200]}`,

  '& .MuiInput-underline:before': {
    display: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 0,
  },
  '& .MuiInputBase-input': {
    paddingRight: '5px',
    marginBottom: '10px',
  },
}));

export const StyledActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 14px',
}));

export const StyledSendButton = styled(IconButton)(({theme}) => ({
  width: '32px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '6px',
  backgroundColor: theme.palette.primaryCustom[1000],
  '&:hover': {
    backgroundColor: theme.palette.primaryCustom[800],
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.basic[200],
  },
}));
