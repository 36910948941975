import {Navigate} from 'react-router-dom';

type GuestGuardProps = {
  children: React.ReactElement;
};

export const GuestGuard = ({children}: GuestGuardProps) => {
  const userId = localStorage.getItem('userId');

  if (userId) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};
