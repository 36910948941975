import {CssBaseline} from '@mui/material';
import {ThemeProvider as MUIThemeProvider, StyledEngineProvider, ThemeOptions, createTheme} from '@mui/material/styles';
import React, {useMemo} from 'react';

import {customShadows} from './customShadows';
import {GlobalStyles} from './globalStyles';
import {componentsOverrides} from './overrides';
import {palette} from './palette';
import {typography} from './typography';

type ThemeProviderProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const ThemeProvider = ({children}: ThemeProviderProps) => {
  const themeOptions = useMemo<ThemeOptions>(
    () => ({
      palette,
      typography,
      customShadows: customShadows(),
    }),
    [],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles theme={theme} />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
