import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {Unit} from '../../api/service-types';
import type {RootState} from '../store';

export function getDefaultLocalStorage() {
  return localStorage.getItem('storageNextUnit') === 'mmol/L' ? 'mmol/L' : 'mg/dL';
}

interface UnitState {
  unit: Unit;
}

export const initialState: UnitState = {
  unit: getDefaultLocalStorage(),
};

export const unitSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    setNextUnit: (state, action: PayloadAction<Unit>) => {
      state.unit = action.payload;
    },
    resetUnitState: () => initialState,
  },
});

export const {setNextUnit, resetUnitState} = unitSlice.actions;
export const unitSelector = (state: RootState) => state.unit;
export default unitSlice.reducer;
