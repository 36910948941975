import {styled} from '@mui/material/styles';
import {Link} from 'react-router-dom';

type StyledLinkProps = {
  disabled?: boolean;
};

export const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== 'disabled',
})<StyledLinkProps>(({theme, disabled}) => ({
  textDecoration: 'none',
  cursor: disabled ? 'default' : 'pointer',
  color: disabled ? theme.palette.basic[300] : theme.palette.primaryCustom[1000],
  '&:hover': {
    color: disabled ? theme.palette.basic[300] : theme.palette.primaryCustom[800],
  },
  '&:focus': {
    outline: '0px',
  },
}));
