import {Snackbar, Alert} from '@mui/material';
import {SnackbarAlertProps} from './types';

export const SnackbarAlert = ({
  open,
  onClose,
  message,
  autoHideDuration = 2000,
  severity,
}: SnackbarAlertProps): JSX.Element => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      autoHideDuration={autoHideDuration}>
      <Alert severity={severity} variant="filled" sx={{width: '100%'}}>
        {message}
      </Alert>
    </Snackbar>
  );
};
