import {Theme} from '@mui/material/styles';

export const Link = (theme: Theme): Theme['components'] => {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.primaryCustom[1000],
        },
      },
    },
  };
};
