import {styled} from '@mui/material/styles';
import {CSSProperties} from 'react';

type SwitchVariant = 'left' | 'right';

type StyledSwitch = {
  isSelected: boolean;
  variant: SwitchVariant;
};

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
}));

export const StyledSwitch = styled('div', {
  shouldForwardProp: prop => prop !== 'isSelected' && prop !== 'variant',
})<StyledSwitch>(({theme, isSelected, variant}) => {
  const stylesMap: Record<SwitchVariant, CSSProperties> = {
    left: {
      borderRadius: '100px 0px 0px 100px',
      borderLeft: `1px solid ${isSelected ? theme.palette.primaryCustom[800] : theme.palette.basic[200]}`,
    },
    right: {
      borderRadius: '0px 100px 100px 0px',
      borderRight: `1px solid ${isSelected ? theme.palette.primaryCustom[800] : theme.palette.basic[200]}`,
    },
  };

  return {
    padding: '7px 0px',
    borderTop: `1px solid ${isSelected ? theme.palette.primaryCustom[800] : theme.palette.basic[200]}`,
    borderBottom: `1px solid ${isSelected ? theme.palette.primaryCustom[800] : theme.palette.basic[200]}`,
    backgroundColor: isSelected ? theme.palette.primaryCustom[800] : theme.palette.basic[0],
    cursor: 'pointer',
    width: '88px',
    textAlign: 'center',
    ...stylesMap[variant],
  };
});
