import {Theme} from '@mui/material/styles';

export const Input = (theme: Theme): Theme['components'] => {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '44px',
          fontFamily: 'Inter',
          '&.Mui-disabled': {
            background: theme.palette.basic[50],
          },
          '&.Mui-disabled > fieldset': {
            borderColor: `${theme.palette.basic[200]} !important`,
          },
          '& > fieldset': {
            border: '1px solid',
            borderRadius: '8px',
            borderColor: theme.palette.basic[200],
          },
          '&.Mui-focused > fieldset': {
            borderColor: theme.palette.primaryCustom[800] + '!important',
          },
          '&:hover > fieldset': {
            borderColor: `${theme.palette.basic[300]} !important`,
          },
        },
        input: {
          '&::placeholder': {
            borderColor: theme.palette.basic[1000],
            fontSize: '13px',
            lineHeight: '20px',
          },
        },
        error: {
          '& > fieldset': {
            borderColor: theme.palette.errorCustom[800] + '!important',
          },
        },
      },
    },
  };
};
