import {BrowserRouter} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';

import {ScrollToTop, ErrorFallback} from '../src/components';
import {Router} from '../src/routes';
import {ThemeProvider} from '../src//theme';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ScrollToTop />
          <Router />
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
