import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')(({theme}) => ({
  borderRadius: '12px',
  padding: '24px',
  background: theme.palette.basic[0],
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'flex-start',
}));

export const StyledImageContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
}));

export const StyledImageInfoContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const StyledInputContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));
