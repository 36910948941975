import {useTheme} from '@mui/material';

export const GetStatusBackground = (status?: string) => {
  const theme = useTheme();

  switch (status) {
    case 'active':
      return theme.palette.primaryCustom[500];
    case 'ended':
      return theme.palette.basic[600];
    case 'new':
      return theme.palette.customColors.darkBlue;
    case 'in-progress':
      return theme.palette.customColors.pacificBlue;
    case 'completed':
      return theme.palette.customColors.green;
    default:
      return theme.palette.customColors.darkGrey;
  }
};
