import {useState, useEffect} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Box, SelectChangeEvent, Typography, useTheme} from '@mui/material';
import {AssignUser, BasicSelect, SnackbarAlert} from '../../components';
import {priorityOptions} from '../../constants/selectionOptions';
import {addSpaceBeforeUppercaseFn} from '../../helpers/commonReusableFns';
import {StatusFilter, EventsStatusFilters, eventsService} from '../../api';
import {usePopover} from '../../hooks';
import {Severity} from '../event-comments/types';
import {DialogBoxEventType} from './DialogBoxEventType';
import {changeFormateDate, changeFormateDateUTC} from '../../utils';
import {StatusComponent} from '../status/Status';

export interface DialogBoxProps {
  dialogOpen: boolean;
  onClose: () => void;
  onRefetch: () => void;
  selectedEvent?: any;
  eventsStatusFilters: EventsStatusFilters;
  uniqueStatuses: StatusFilter[];
}

export const DialogBox = (props: DialogBoxProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {onClose, onRefetch, dialogOpen, selectedEvent, uniqueStatuses} = props;
  const {handleClose} = usePopover<HTMLButtonElement>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [severity, setSeverity] = useState<Severity>('success');
  const [message, setMessage] = useState<string>('');
  const [selectedPriority, setSelectedPriority] = useState<string>(selectedEvent?.event?.priority);

  const [updateEvent] = eventsService.useUpdateEventMutation();

  const eventTypeWholeString = selectedEvent?.originatingEvent.type;
  const eventTypeParts = eventTypeWholeString?.split('.');
  const eventTypeUnformatted = eventTypeParts && eventTypeParts[eventTypeParts?.length - 1];
  const eventType = eventTypeUnformatted && addSpaceBeforeUppercaseFn(eventTypeUnformatted);

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handlePriorityChange = async (e: SelectChangeEvent) => {
    try {
      await updateEvent({
        body: {
          eventId: selectedEvent?.event?.id,
          //@ts-expect-error: events need to be refactored
          eventType: selectedEvent?.event?.type,
          priority: Number(e?.target?.value),
        },
      });
      setMessage('Event priority updated successfully!');
      setSeverity('success');
      setOpenSnackbar(true);
      handleClose();
      setSelectedPriority(e?.target?.value);
      //onRefetch();
    } catch (e) {
      setMessage('Error while updating event priority!');
      setSeverity('error');
      setOpenSnackbar(true);
      handleClose();
    }
  };

  const defaultPriorityValue = selectedEvent?.event?.priority?.toString();

  useEffect(() => {
    setSelectedPriority(defaultPriorityValue);
  }, [defaultPriorityValue, onClose]);

  return (
    <>
      <Dialog fullScreen={fullScreen} fullWidth={true} onClose={onClose} open={dialogOpen}>
        <StatusComponent
          onClose={onClose}
          selectedEvent={selectedEvent}
          uniqueStatuses={uniqueStatuses}
          onRefetch={onRefetch}
        />
        <DialogContent dividers>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '72px'}}>
              ID:
            </Typography>
            <Typography variant="bodySmall">{selectedEvent?.event?.id}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '72px'}}>
              Priority:
            </Typography>
            <BasicSelect
              background={selectedPriority === '3' ? theme.palette.errorCustom[800] : theme.palette.basic[300]}
              color={theme.palette.basic[0]}
              colorModeSelected="white"
              label="Priority"
              defaultValue={selectedEvent?.event?.priority?.toString()}
              value={selectedPriority}
              menuItems={priorityOptions}
              handleChange={handlePriorityChange}
            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '72px'}}>
              Type:
            </Typography>
            <Typography variant="bodySmall">{eventType}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '72px'}}>
              Event info:
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <DialogBoxEventType {...selectedEvent?.event} />
            </Box>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '72px'}}>
              Assign:
            </Typography>
            <AssignUser event={selectedEvent} onRefetch={onRefetch} />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Typography variant="bodySmall" sx={{width: '72px'}}>
              UTC Date:
            </Typography>
            <Typography variant="bodySmall">
              {selectedEvent?.originatingEvent.eventUTCInstant
                ? changeFormateDateUTC(selectedEvent?.originatingEvent.eventUTCInstant)
                : 'No event date available!'}
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Typography variant="bodySmall" sx={{width: '72px'}}>
              Local Date:
            </Typography>
            <Typography variant="bodySmall">
              {selectedEvent?.originatingEvent.eventLocalDateTime
                ? changeFormateDate(selectedEvent?.originatingEvent.eventLocalDateTime)
                : 'No event date available!'}
            </Typography>
          </Box>
          {/* {selectedEvent && <EventComments event={selectedEvent} />} */}
        </DialogContent>
      </Dialog>
      <SnackbarAlert open={openSnackbar} onClose={handleSnackbarClose} message={message} severity={severity} />
    </>
  );
};
