import {Link as RouterLink} from 'react-router-dom';

import {Button, Container, Typography} from '@mui/material';

import {StyledContent} from './styles';

export const Page404 = () => {
  return (
    <>
      <Container>
        <StyledContent sx={{textAlign: 'center', alignItems: 'center'}}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography paragraph sx={{color: 'text.secondary'}}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink} disableFocusRipple>
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </>
  );
};
