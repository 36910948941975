import {useTheme, TableHead as MuiTableHead, TableCellProps, Typography} from '@mui/material';

import {StyledHeadTableRow, StyledHeadTableCell} from './styles';

export type TableHeadCell = {
  label: string;
  align?: TableCellProps['align'];
  width?: TableCellProps['width'];
};

type TableHeadProps = {
  cells: TableHeadCell[];
};

export const TableHead = ({cells}: TableHeadProps): JSX.Element => {
  const theme = useTheme();

  return (
    <MuiTableHead>
      <StyledHeadTableRow>
        {cells.map(cell => {
          return (
            <StyledHeadTableCell key={cell.label} align={cell.align ?? 'inherit'} width={cell.width}>
              <Typography variant="bodyBig" color={theme.palette.basic[800]}>
                {cell.label}
              </Typography>
            </StyledHeadTableCell>
          );
        })}
      </StyledHeadTableRow>
    </MuiTableHead>
  );
};
