import {Box, Typography, IconButton, useTheme} from '@mui/material';
import {eventsService, StatusFilter} from '../../api';
import {FormattedStatus} from '../../sections/events/events-table/utils';
import {GetStatusBackground} from '../../utils/style';
import {BasicSearchableSelect} from '../select';
import {useEffect, useState} from 'react';
import {usePopover} from '../../hooks';
import {Severity} from '../event-comments/types';

import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import {SnackbarAlert} from '../snackbar';

export interface StatusComponentProps {
  onClose: () => void;
  onRefetch: () => void;
  selectedEvent?: any;
  uniqueStatuses: StatusFilter[];
}

export const StatusComponent = (props: StatusComponentProps) => {
  const {selectedEvent, uniqueStatuses, onClose} = props;
  const theme = useTheme();

  const [updateEvent] = eventsService.useUpdateEventMutation();
  const [selectedStatus, setSelectedStatus] = useState<StatusFilter | null>();
  const {handleClose} = usePopover<HTMLButtonElement>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [severity, setSeverity] = useState<Severity>('success');
  const [message, setMessage] = useState<string>('');

  const updateEventStatus = async (nextSelectedStatus: StatusFilter) => {
    try {
      await updateEvent({
        body: {
          eventId: selectedEvent?.event?.id,
          //@ts-expect-error: events need to be refactored
          eventType: selectedEvent?.event?.type,
          status:
            selectedStatus?.status?.toLowerCase() !== nextSelectedStatus?.status?.toLowerCase()
              ? nextSelectedStatus?.status?.toLowerCase()
              : 'null',
        },
      });
      setMessage('Event status updated successfully!');
      setSeverity('success');
      setOpenSnackbar(true);
      handleClose();
      selectedStatus?.status?.toLowerCase() !== nextSelectedStatus?.status?.toLowerCase()
        ? setSelectedStatus(nextSelectedStatus)
        : setSelectedStatus(null);
    } catch (e) {
      setMessage('Error while updating event status!');
      setSeverity('error');
      setOpenSnackbar(true);
      handleClose();
    }
  };

  const defaultStatusOption = uniqueStatuses.find(
    option => option.status.toLowerCase() === selectedEvent?.event?.status,
  );
  const defaultStatusValue = defaultStatusOption || null;

  useEffect(() => {
    setSelectedStatus(defaultStatusValue);
  }, [defaultStatusValue]);

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'row', padding: '8px 16px 8px 16px'}}>
      <BasicSearchableSelect
        defaultValue={defaultStatusValue}
        value={selectedStatus}
        placeholder="Status"
        options={uniqueStatuses}
        onChange={(nextSelectedStatus: StatusFilter) => updateEventStatus(nextSelectedStatus)}
        //@ts-expect-error: events need to be refactored
        width={108}
        searchKeys={['status']}
        keyExtractor={(option: FormattedStatus) => String(option.id)}
        renderOption={(option: FormattedStatus, isSelected: boolean) => (
          <Box sx={{display: 'flex', flexDirection: 'row', mt: '2.5px', ml: '-5px'}}>
            <CircleIcon
              sx={{
                height: '8px',
                width: '8px',
                mt: '5px',
                mr: '5px',
                color: GetStatusBackground(status),
              }}
            />
            <Typography
              variant={isSelected ? 'bodyBigSemibold' : 'bodyBig'}
              color={isSelected ? theme.palette.primaryCustom[1000] : theme.palette.basic[800]}>
              {option.status !== 'null' ? option.status : 'N/A'}
            </Typography>
          </Box>
        )}
      />
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}>
        <CloseIcon sx={{mt: '-2.5px'}} />
      </IconButton>
      <SnackbarAlert open={openSnackbar} onClose={handleSnackbarClose} message={message} severity={severity} />
    </Box>
  );
};
