import {Theme} from '@mui/material/styles';
import {GlobalStyles as MUIGlobalStyles} from '@mui/material';

type GlobalStylesProps = {
  theme: Theme;
};

export const GlobalStyles = ({theme}: GlobalStylesProps) => {
  return (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        // TODO remove after debugging
        iframe: {
          display: 'none',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.Toastify__toast-container': {
          padding: 0,
          bottom: '32px',
          right: '32px',
        },
        '.Toastify__toast': {
          padding: '0px',
          borderRadius: '8px',
          marginBottom: '8px',
          boxShadow: '0px 2px 10px 0px rgba(147, 149, 149, 0.4)',
          border: '1px solid rgba(244, 19, 63, 1)',
        },
        '.Toastify__toast:last-of-type': {
          marginBottom: '0px',
        },
        '.Toastify__toast-body': {
          padding: '0px',
        },
        '.Toastify__toast-close': {
          color: theme.palette.basic[600],
        },
        '*::-webkit-scrollbar': {
          width: '3px',
        },
        '*::-webkit-scrollbar-track': {
          background: '#DCDEDE',
        },
      }}
    />
  );
};
