import {Box, List} from '@mui/material';

import {Logo, Scrollbar} from '../../../components';
import {NavItem} from './NavItem';
import {getClinicalTrialsByStatus, getNavigationTree} from './config';
import {clinicalTrialsService} from '../../../api';

export const NavBarContent = () => {
  const {data: clinicalTrials = []} = clinicalTrialsService.useGetClinicalTrialsQuery();

  const clinicalTrialsByStatus = getClinicalTrialsByStatus(clinicalTrials);
  const navigationTree = getNavigationTree(clinicalTrialsByStatus);

  return (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {height: 1, display: 'flex', flexDirection: 'column'},
      }}>
      <Box sx={{px: 2.5, py: 3, display: 'inline-flex'}}>
        <Logo />
      </Box>

      <Box>
        <List disablePadding>
          {navigationTree.map(item => (
            <NavItem key={item.title} item={item} />
          ))}
        </List>
      </Box>
    </Scrollbar>
  );
};
