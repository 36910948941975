import {Dispatch} from 'redux';
import {
  authService,
  clinicalTrialsService,
  eventsService,
  measurementsService,
  participantsService,
  userService,
} from '../../api';

export const resetServices = () => (dispatch: Dispatch) => {
  dispatch(authService.util.resetApiState());
  dispatch(clinicalTrialsService.util.resetApiState());
  dispatch(eventsService.util.resetApiState());
  dispatch(measurementsService.util.resetApiState());
  dispatch(participantsService.util.resetApiState());
  dispatch(userService.util.resetApiState());
};
