import {TypographyOptions} from '@mui/material/styles/createTypography';

export const remToPx = (value: string) => Math.round(parseFloat(value) * 16);

export const pxToRem = (value: number) => `${value / 16}rem`;

type ResponsiveFontSizesProps = {
  sm: number;
  md: number;
  lg: number;
};

export const responsiveFontSizes = ({sm, md, lg}: ResponsiveFontSizesProps) => {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
};

const FONT_PRIMARY = 'Inter';

export const typography: TypographyOptions = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h0: {
    fontWeight: 600,
    lineHeight: '56px',
    fontSize: pxToRem(42),
    ...responsiveFontSizes({sm: 38, md: 40, lg: 42}),
  },
  h1: {
    fontWeight: 600,
    lineHeight: '38px',
    fontSize: pxToRem(32),
    ...responsiveFontSizes({sm: 24, md: 28, lg: 32}),
  },
  h2: {
    fontWeight: 600,
    lineHeight: '40px',
    fontSize: pxToRem(22),
    ...responsiveFontSizes({sm: 18, md: 20, lg: 22}),
  },
  h3: {
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: pxToRem(20),
    ...responsiveFontSizes({sm: 16, md: 18, lg: 20}),
  },
  h4: {
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: pxToRem(16),
    ...responsiveFontSizes({sm: 12, md: 14, lg: 16}),
  },
  bodyBig: {
    fontWeight: 400,
    lineHeight: '20px',
    fontSize: pxToRem(13),
  },
  bodyBigSemibold: {
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: pxToRem(13),
  },
  bodySmall: {
    fontWeight: 400,
    lineHeight: '18px',
    fontSize: pxToRem(12),
  },
  bodySmallBold: {
    fontWeight: 600,
    lineHeight: '16px',
    fontSize: pxToRem(12),
  },
  bodyExtraSmall: {
    fontWeight: 400,
    lineHeight: '16px',
    fontSize: pxToRem(11),
  },
  captionBig: {
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: pxToRem(13),
  },
  captionSmall: {
    fontWeight: 600,
    lineHeight: '16px',
    fontSize: pxToRem(12),
  },
  uppercaseMedium: {
    fontWeight: 400,
    lineHeight: '16px',
    fontSize: pxToRem(11),
    textTransform: 'uppercase',
  },
  uppercaseSmall: {
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: pxToRem(10),
    textTransform: 'uppercase',
  },
  caption3: {
    fontWeight: 600,
    lineHeight: '10px',
    fontSize: pxToRem(8),
  },
  linkHeading: {
    color: 'black',
    textDecoration: 'none',
  },
};
