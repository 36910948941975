import {createApi, BaseQueryFn} from '@reduxjs/toolkit/query/react';

import {httpsUrl} from '../../constants';
import {baseQuery} from '../../helpers';
import type {Measurement} from '../service-types';
import type {IdParams} from '../query-types';

const measurementsApiBuilder = (baseQuery: BaseQueryFn) =>
  createApi({
    reducerPath: 'measurements-service',
    baseQuery,
    endpoints: builder => ({
      getCgmMeasurementById: builder.query<Measurement, {params: IdParams}>({
        query({params}) {
          return {
            url: `/measurements/cgm/${params.id}`,
            method: 'GET',
          };
        },
      }),
      getLatestCgmMeasurements: builder.query<Measurement[], void>({
        query() {
          return {
            url: '/measurements/cgm/latest-for-all-participants',
            method: 'GET',
          };
        },
      }),
      getLatestCgmActiveMeasurements: builder.query<Measurement[], void>({
        query() {
          return {
            url: '/measurements/cgm/latest-for-participants-of-active-clinical-trials',
            method: 'GET',
          };
        },
      }),
      getLatestCgmMeasurementsByClinicalTrialId: builder.query<Measurement[], {params: IdParams}>({
        query({params}) {
          return {
            url: `/measurements/cgm/latest-for-participants-of-clinical-trial/${params.id}`,
            method: 'GET',
          };
        },
      }),
    }),
  });

export const measurementsService = measurementsApiBuilder(baseQuery(httpsUrl));
