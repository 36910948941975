import {LoadingButton} from '@mui/lab';
import {Stack, Typography, useTheme} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {StyledContent} from './styles';

export const SuccessfulPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToLoginPage = () => {
    navigate('/auth/login', {replace: true});
  };

  return (
    <StyledContent>
      <Typography variant="h1" gutterBottom>
        Password changed successfully
      </Typography>
      <Typography variant="bodyBig" gutterBottom color={theme.palette.basic[800]}>
        Please log in with your new password
      </Typography>
      <Stack sx={{my: 2}}>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={navigateToLoginPage}
          disableFocusRipple>
          Log in
        </LoadingButton>
      </Stack>
    </StyledContent>
  );
};
