import {Outlet, useLocation} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {useEffect} from 'react';

import {customToast} from '../../components';
import {Header} from './header';
import {NavBar} from './nav';
import {Events} from './events';
import {wsService} from '../../api';
import {useBoolean} from '../../hooks';

import {StyledMain, StyledRoot} from './styles';
import {IsMobile} from '../../utils/IsMobile';

const regex = /^\/dashboard\/\d+$/;

export const DashboardLayout = () => {
  const {pathname} = useLocation();
  const {value: isOpened, setTrue: onOpen, setFalse: onClose} = useBoolean();
  const areEventsShown = regex.test(pathname);
  wsService.connect();

  useEffect(() => {
    wsService.listener(event => {
      if (event.priority === 3) customToast({event});
    });
  }, []);

  return (
    <StyledRoot>
      <Header onOpenNav={onOpen} />
      <NavBar openNav={isOpened} onCloseNav={onClose} />
      <StyledMain areEventsShown={areEventsShown}>
        <Outlet />
      </StyledMain>
      {IsMobile() ? null : areEventsShown && <Events />}
      {IsMobile() ? null : areEventsShown && <ToastContainer limit={10} />}
    </StyledRoot>
  );
};
