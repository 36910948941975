import {CustomShadows, alpha} from '@mui/material/styles';

import {palette} from './palette';

export const customShadows = (): CustomShadows => {
  return {
    down: `0 4px 16px 0 ${alpha(palette?.basic?.[200] || '', 0.32)}`,
    around: `0 4px 8px 0 ${alpha(palette?.basic?.[200] || '', 0.24)}`,
    intensiveAround: `0 2px 10px 0 ${alpha(palette?.basic?.[600] || '', 0.4)}`,
  };
};
