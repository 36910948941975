import {styled} from '@mui/material/styles';
import {TableRow, TableCell} from '@mui/material';

export const StyledHeadTableRow = styled(TableRow)(({theme}) => ({
  borderTop: `1px solid ${theme.palette.basic[100]}`,
  borderBottom: `1px solid ${theme.palette.basic[100]}`,
}));

export const StyledHeadTableCell = styled(TableCell)(() => ({
  padding: '12px 16px',
  border: 'none',
}));
