import {
  useTheme,
  Typography,
  TypographyProps,
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  SxProps,
  Theme,
} from '@mui/material';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type AvatarProps = Omit<MuiAvatarProps, 'src'> & {
  src?: string | null;
  initials: string;
  size: Size;
  typographyVariant: TypographyProps['variant'];
};

const sizeMap: Record<Size, SxProps<Theme>> = {
  xs: {width: 24, height: 24},
  sm: {width: 30, height: 30},
  md: {width: 32, height: 32},
  lg: {width: 40, height: 40},
  xl: {width: 96, height: 96},
};

export const Avatar = ({src, initials, size, typographyVariant, ...props}: AvatarProps): JSX.Element => {
  const theme = useTheme();

  if (!src) {
    return (
      <MuiAvatar sx={{...sizeMap[size], backgroundColor: theme.palette.primaryCustom[800]}} {...props}>
        <Typography variant={typographyVariant} color={theme.palette.basic[0]}>
          {initials}
        </Typography>
      </MuiAvatar>
    );
  }
  return <MuiAvatar src={src} sx={sizeMap[size]} />;
};
