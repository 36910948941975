import {wssUrl} from '../../constants';
import type {EventResponse} from '../../api/service-types';

class WSService {
  ws: WebSocket | null;
  constructor() {
    this.ws = null;
  }
  connect() {
    if (this.ws) {
      console.warn('WebSocket is already connected.');
      return;
    }
    this.ws = new WebSocket(`${wssUrl}/event-notifications/ws`);

    this.ws.addEventListener('open', EventResponse => {
      console.log(`WebSocket type: ${EventResponse.type}`);
    });

    this.ws.addEventListener('close', EventResponse => {
      console.log(`WebSocket type: ${EventResponse.type}`);
    });

    this.ws.addEventListener('error', EventResponse => {
      console.log(`WebSocket type: ${EventResponse.type}`);
    });
  }

  disconnect() {
    if (!this.ws) return;
    this.ws.close();
    this.ws = null;
  }

  listener(callback: (nextEvent: EventResponse) => void) {
    if (!this.ws) return;
    this.ws.addEventListener('message', ({data}: MessageEvent['data']) => {
      try {
        const nextEvent: EventResponse = JSON.parse(data);
        callback(nextEvent);
      } catch (error) {
        console.log('error', error);
      }
    });
  }
}

export const wsService = new WSService();
