import {useTheme, Typography, PaginationItem} from '@mui/material';

import {Icon} from '../icon';
import {StyledContainer, StyledRowsPerPage, StyledMenuItem, StyledSelect, StyledPagination} from './styles';

type TableNavigationPanelProps = {
  page: number;
  rowsNumber: number;
  rowsPerPage: number;
  onChangePage: (nextPage: number) => void;
  onChangeRowsPerPage: (nextRowsPerPage: number) => void;
};

const options = [10, 20, 30, 40, 50];

export const TableNavigationPanel = ({
  page,
  rowsNumber,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}: TableNavigationPanelProps): JSX.Element => {
  const theme = useTheme();

  const count = Math.ceil(rowsNumber / rowsPerPage);

  return (
    <StyledContainer>
      <StyledRowsPerPage>
        <StyledSelect
          value={rowsPerPage}
          onChange={e => {
            onChangeRowsPerPage(Number(e.target.value));
            onChangePage(1);
          }}
          IconComponent={props => <Icon icon="down" size={16} {...props} />}
          MenuProps={{
            PaperProps: {
              sx: {
                padding: '4px 8px',
                borderRadius: '8px',
                boxShadow: theme.customShadows.around,
                '.MuiList-root': {
                  padding: '0px',
                },
              },
            },
          }}>
          {options.map(option => {
            return (
              <StyledMenuItem key={option} value={option} disableRipple>
                <Typography variant="bodyBig">{option}</Typography>
              </StyledMenuItem>
            );
          })}
        </StyledSelect>
        <Typography variant="bodyBig" color={theme.palette.basic[800]}>
          of {rowsNumber} results
        </Typography>
      </StyledRowsPerPage>
      <StyledPagination
        page={page}
        count={count}
        onChange={(_, nextPage) => {
          onChangePage(nextPage);
        }}
        renderItem={item => (
          <PaginationItem
            components={{
              previous: () => <Icon icon="left" size={16} />,
              next: () => <Icon icon="right" size={16} />,
            }}
            {...item}
          />
        )}
      />
    </StyledContainer>
  );
};
