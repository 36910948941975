import {Theme} from '@mui/material/styles';

export const Button = (theme: Theme): Theme['components'] => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
        contained: {
          backgroundColor: theme.palette.primaryCustom[800],
          '&:hover': {
            backgroundColor: theme.palette.primaryCustom[1000],
          },
        },
        text: {
          backgroundColor: theme.palette.basic[0],
          color: theme.palette.primaryCustom[1000],
          '&:hover': {
            backgroundColor: theme.palette.basic[0],
            color: theme.palette.primaryCustom[800],
          },
          '&:disabled': {
            color: theme.palette.basic[300],
          },
        },
      },
    },
  };
};
