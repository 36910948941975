import {Box, Stack} from '@mui/material';

import {IconButton, Icon} from '../../../components';
import {AccountPopover} from './AccountPopover';

import {StyledRoot, StyledToolbar} from './styles';

type HeaderProps = {
  onOpenNav: () => void;
};

export const Header = ({onOpenNav}: HeaderProps) => {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: {lg: 'none'},
          }}>
          <Icon icon="table-view" />
        </IconButton>
        <Box sx={{flexGrow: 1}} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}>
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
};
