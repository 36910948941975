import {createApi, BaseQueryFn} from '@reduxjs/toolkit/query/react';

import {httpsUrl} from '../../constants';
import {baseQuery} from '../../helpers';
import type {Participant, OverallScreenData, DynamicData} from '../service-types';
import type {IdParams} from '../query-types';

const participantsApiBuilder = (baseQuery: BaseQueryFn) =>
  createApi({
    reducerPath: 'participants-service',
    baseQuery,
    endpoints: builder => ({
      getParticipants: builder.query<Participant[], void>({
        query() {
          return {
            url: '/participants?timeframe=all',
            method: 'GET',
          };
        },
      }),
      getParticipantById: builder.query<Participant, {params: IdParams}>({
        query({params}) {
          return {
            url: `/participants/${params.id}`,
            method: 'GET',
          };
        },
      }),
      getParticipantsByClinicalTrialId: builder.query<Participant[], {params: IdParams}>({
        query({params}) {
          return {
            url: `/participants?clinical_trial_id=${params.id}`,
            method: 'GET',
          };
        },
      }),
      getOverallScreenData: builder.query<OverallScreenData, {params: IdParams}>({
        query({params}) {
          return {
            url: `/participants/screen-data/${params.id}`,
            method: 'GET',
          };
        },
      }),
      getDynamicScreenData: builder.query<DynamicData, {params: IdParams}>({
        query({params}) {
          return {
            url: `/participants/screen-data/dynamic/${params.id}`,
            method: 'GET',
          };
        },
      }),
    }),
  });

export const participantsService = participantsApiBuilder(baseQuery(httpsUrl));
