import {DynamicData} from '../../../api';
import {useRef, useEffect, useState} from 'react';
import {scaleTime, scaleLinear} from '@visx/scale';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import {timeSampConvert} from '../utils';
import correctionBolusIcon from './assets/correctionBolusIcon.svg';
import carbohydratesIcon from './assets/carbohydratesIcon.svg';
import mealBolusIcon from './assets/mealBolusIcon.svg';
import prandialBolusIcon from './assets/prandialBolusIcon.svg';

interface DataPoint {
  x: string;
  y: number;
  icon: string; // O ícone para representar o tipo
  label: string; // Rótulo para o tooltip e exibição abaixo do ícone
}

export const BolusAndTreatmentChart = ({boluses, mealEvents}: DynamicData): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [width, setWidth] = useState(1000);
  const [mouseX, setMouseX] = useState<number | null>(null); // crosshair
  const [tooltipData, setTooltipData] = useState<DataPoint | null>(null); // Dados para o tooltip
  const [tooltipPos, setTooltipPos] = useState<{x: number; y: number} | null>(null); // Posição do tooltip
  const height = 300;
  const margin = {top: 20, right: 20, bottom: 50, left: 60}; // Ajuste o left para o eixo Y

  // Processar mealEvents
  const carbohydrates: DataPoint[] = mealEvents
    .filter(event => !event.isHypoTreatment)
    .map(event => ({
      x: timeSampConvert(event.eventUTCInstant),
      y: 50, // Linha superior
      icon: carbohydratesIcon,
      label: 'Carbohydrates',
    }));

  const correctionBoluses: DataPoint[] = mealEvents
    .filter(event => event.isHypoTreatment)
    .map(event => ({
      x: timeSampConvert(event.eventUTCInstant),
      y: 50, // Linha superior
      icon: correctionBolusIcon,
      label: 'Correction Bolus',
    }));

  // Processar boluses
  const mealBolus: DataPoint[] = boluses
    .filter(bolus => !bolus.isPrandial)
    .map(bolus => ({
      x: timeSampConvert(bolus.eventUTCInstant),
      y: 150, // Linha inferior
      icon: mealBolusIcon,
      label: 'Meal Bolus',
    }));

  const prandialBoluses: DataPoint[] = boluses
    .filter(bolus => bolus.isPrandial)
    .map(bolus => ({
      x: timeSampConvert(bolus.eventUTCInstant),
      y: 150, // Linha inferior
      icon: prandialBolusIcon,
      label: 'Prandial Bolus',
    }));

  // Combinar todos os dados
  const dataPoints: DataPoint[] = [...carbohydrates, ...correctionBoluses, ...mealBolus, ...prandialBoluses];

  // Calcular os limites de tempo
  const minX = Math.min(...dataPoints.map(d => new Date(d.x).getTime()));
  const maxX = Math.max(...dataPoints.map(d => new Date(d.x).getTime()));

  // Calcular os limites de Y
  const minY = 0;
  const maxY = 200; // Ajuste conforme necessário

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Escala de tempo para o eixo X
  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });

  // Escala linear para o eixo Y
  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  // Função para capturar o movimento do mouse e exibir a crosshair
  const handleMouseMove = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    const {clientX} = event;
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const mouseXPosition = clientX - svgRect.left;
    setMouseX(mouseXPosition);
  };

  const handleMouseLeave = () => {
    setMouseX(null); // Esconder a crosshair quando o mouse sair do gráfico
    setTooltipData(null); // Esconder o tooltip
  };

  // Função para capturar o hover sobre ícones e mostrar o tooltip
  const handleIconMouseOver = (data: DataPoint, event: React.MouseEvent) => {
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const mouseXPosition = event.clientX - svgRect.left;
    const mouseYPosition = event.clientY - svgRect.top;

    setTooltipData(data);
    setTooltipPos({x: mouseXPosition, y: mouseYPosition});
  };

  // Função para ocultar o tooltip quando o mouse sai do ícone
  const handleIconMouseOut = () => {
    setTooltipData(null);
  };

  // Renderizar ícones e labels
  const renderIconsAndLabels = (data: DataPoint[]) =>
    data.map((d, i) => (
      <g key={`item-${i}`} onMouseOver={event => handleIconMouseOver(d, event)} onMouseOut={handleIconMouseOut}>
        <image
          href={d.icon}
          x={xScale(new Date(d.x)) - 30} // Centralizar o ícone horizontalmente
          y={yScale(d.y) - 30} // Centralizar o ícone verticalmente
          width={60}
          height={60}
        />
        <text
          x={xScale(new Date(d.x))} // Centralizar o label com o ícone
          y={yScale(d.y) + 35} // Posicionar o label abaixo do ícone
          textAnchor="middle"
          fontSize={12}
          fill="black"
          pointerEvents="none" // Não capturar eventos do mouse
        >
          {d.y} {/* Exibir o valor y abaixo do ícone */}
        </text>
      </g>
    ));

  return (
    <div style={{position: 'relative'}}>
      <svg ref={svgRef} width="100%" height={height}>
        <Group>
          {/* Desenhar eixo X */}
          <AxisBottom
            top={height - margin.bottom}
            scale={xScale}
            tickLabelProps={() => ({
              fill: 'black',
              fontSize: 11,
              textAnchor: 'middle',
            })}
          />
          {/* Desenhar eixo Y */}
          <AxisLeft
            top={margin.top}
            left={margin.left}
            scale={yScale}
            tickLabelProps={() => ({
              fill: 'black',
              fontSize: 11,
              textAnchor: 'end',
            })}
          />

          {/* Renderizar os ícones e labels */}
          {renderIconsAndLabels(dataPoints)}

          {/* Desenhar crosshair vertical */}
          {mouseX !== null && (
            <line
              x1={mouseX}
              x2={mouseX}
              y1={margin.top}
              y2={height - margin.bottom}
              stroke="gray"
              strokeDasharray="4,4"
            />
          )}

          {/* Capturar eventos do mouse */}
          <rect
            x={margin.left}
            y={margin.top}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
            fill="transparent"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </Group>
      </svg>

      {/* Exibir tooltip */}
      {tooltipData && tooltipPos && (
        <div
          style={{
            position: 'absolute',
            top: tooltipPos.y, // Ajustar para aparecer acima do ícone
            left: tooltipPos.x, // Ajustar para aparecer à direita do ícone
            backgroundColor: 'white',
            padding: '5px',
            border: '1px solid gray',
            borderRadius: '4px',
            pointerEvents: 'none',
            fontSize: '12px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 10, // Para garantir que o tooltip fique acima do gráfico
          }}>
          {tooltipData.label}
        </div>
      )}
    </div>
  );
};
