import {LoadingButton} from '@mui/lab';

import {Stack, Typography, Button, useTheme} from '@mui/material';

import {hideEmail} from '../../helpers';

import {StyledContent} from './styles';

// TODO remove mock when back-end will be ready
const emailMock = 'john.smith@gmail.com';

type SuccessfulEmailProps = {
  sentEmailUnsuccessfully: () => void;
};

export const SuccessfulEmail = ({sentEmailUnsuccessfully}: SuccessfulEmailProps): JSX.Element => {
  const theme = useTheme();

  const email = hideEmail(emailMock);

  const sendAgainHandler = () => {
    // TODO add "Send again handle"
  };

  return (
    <StyledContent>
      <Typography variant="h1" gutterBottom>
        Email sent successfully
      </Typography>
      <Typography variant="bodyBig" gutterBottom color={theme.palette.basic[800]}>
        Please check {email}
      </Typography>

      <Stack spacing={1} sx={{my: 2}}>
        <Typography variant="bodyBig">Didn’t receive the email?</Typography>
        <LoadingButton
          fullWidth
          size="large"
          type="button"
          variant="contained"
          onClick={sendAgainHandler}
          disableFocusRipple>
          Send again
        </LoadingButton>
      </Stack>

      <Button fullWidth size="large" type="button" variant="text" onClick={sentEmailUnsuccessfully} disableFocusRipple>
        Enter another email
      </Button>
    </StyledContent>
  );
};
