import {FormControl, MenuItem, OutlinedInput, styled} from '@mui/material';

import {Color} from '../../../interfaces/styles';

type StyledContainerProps = {
  background: Color;
};

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: prop => prop !== 'background',
})<StyledContainerProps>(({theme, background}) => ({
  backgroundColor: theme.palette.basic[50],
  borderRadius: '8px',
  background,

  '& > .MuiInputBase-formControl': {
    padding: 0,
    height: '32px',
  },

  '&:has([aria-expanded="true"])': {
    backgroundColor: theme.palette.primaryCustom[50],

    '& .MuiSelect-icon': {
      color: theme.palette.primaryCustom[1000],
    },

    '& .MuiInputLabel': {
      color: theme.palette.primaryCustom[1000],
    },
    '& .Mui-focused': {
      color: theme.palette.primaryCustom[1000],
    },
  },
}));

export const StyledOutlinedInput = styled(OutlinedInput)(({theme}) => ({
  color: theme.palette.basic[0],
  backgroundColor: 'transparent',

  '&:has([aria-expanded="true"])': {
    border: '1px solid',
    borderRadius: '8px',
    borderColor: theme.palette.primaryCustom[1000],
  },

  '& .MuiSelect-icon': {
    color: theme.palette.basic[0],
  },

  '& > fieldset': {
    border: 'none',
  },
}));

export const StyledPopoverContent = styled('div')(() => ({
  width: 'fit-content',
  minWidth: '270px',
  padding: '8px',
}));

export const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  padding: '0',
  paddingRight: '12px',
  borderRadius: '8px',

  '&:hover': {
    backgroundColor: theme.palette.primaryCustom[50],
  },
}));
