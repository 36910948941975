import {createApi, BaseQueryFn} from '@reduxjs/toolkit/query/react';

import {httpsUrl} from '../../constants';
import {baseQuery} from '../../helpers';
import type {User, UserMutationUpdate} from '../service-types';
import type {IdParams} from '../query-types';

const userApiBuilder = (baseQuery: BaseQueryFn) =>
  createApi({
    reducerPath: 'user-service',
    tagTypes: ['USER'],
    baseQuery,
    endpoints: builder => ({
      getUsers: builder.query<User[], void>({
        query() {
          return {
            url: '/users',
            method: 'GET',
          };
        },
        providesTags: [{type: 'USER'}],
      }),
      getUserById: builder.query<User, {params: IdParams}>({
        query({params}) {
          return {
            url: `/users/${params.id}`,
            method: 'GET',
          };
        },
        providesTags: [{type: 'USER'}],
      }),
      updateUser: builder.mutation<User, {body: UserMutationUpdate}>({
        query({body}) {
          return {
            url: '/users',
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: [{type: 'USER'}],
      }),
    }),
  });

export const userService = userApiBuilder(baseQuery(httpsUrl));
