import {useTheme, Typography, Tooltip, Box} from '@mui/material';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import Chip from '@mui/material/Chip';
import {Icon, InsulinTrendIcon, ReservoirIcon} from '../../../components';
import {DeviceCardProps} from './types';

import {
  StyledContainer,
  StyledContent,
  StyledLastUpdate,
  StyledTimeValue,
  StyledValueContainer,
  StyledValue,
  StyledOperationMode,
  StyledIconButton,
} from './styles';
import moment from 'moment-timezone';
import {timeZone} from '../../../utils';
import {IsMobile} from '../../../utils/IsMobile';

export const DeviceCard = ({
  cardHeading,
  showReservoirDetails,
  reservoirPercentage,
  showBatteryPercentage,
  batteryPercentage,
  lastUpdatedTimeTitle,
  lastUpdatedTime,
  showReadingValueAndUnit,
  showLastHoursInsulin,
  readingValue,
  readingUnit,
  readingTrend,
  isInsulinCard,
  tooltipText,
  showLastCarboMeal,
}: DeviceCardProps): JSX.Element => {
  const theme = useTheme();
  const dateTimeAgo = lastUpdatedTime && moment(new Date()).tz(timeZone).fromNow(); // TODO: MAKE ICON GRAY IF HAS PASSED MORE THEN 5MIN

  return (
    <StyledContainer>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <StyledOperationMode>
          <Typography sx={{fontSize: '12px', fontWeight: '600', color: `${theme.palette.basic[900]}`}}>
            {cardHeading}
          </Typography>
          <Tooltip title={tooltipText}>
            <StyledIconButton>
              <Icon icon="info" size={IsMobile() ? 12 : 16} color={theme.palette.basic[500]} />
            </StyledIconButton>
          </Tooltip>
        </StyledOperationMode>
        {!isInsulinCard && (
          <Box sx={{display: 'flex', flexDirection: 'row', ml: '46px'}}>
            {showReservoirDetails && (
              <>
                <ReservoirIcon />
                <Typography sx={{fontSize: '12px', color: '#535B62', mt: '3.5px', mr: '8px', ml: '2px'}}>
                  {reservoirPercentage}
                </Typography>
              </>
            )}
            {showBatteryPercentage && (
              <div style={{display: 'inline'}}>
                <BatteryCharging90Icon sx={{transform: 'rotate(-90deg)'}} />
                <Typography sx={{fontSize: '12px', color: '#535B62', mt: '3.5px', mr: '8px', ml: '2px'}}>
                  {batteryPercentage}%
                </Typography>
              </div>
            )}
            {!IsMobile() && (
              <Chip
                label="CONNECTED"
                size={'small'}
                style={{backgroundColor: 'rgb(136, 144, 158)', color: '#fafafa'}}
              />
            )}
          </Box>
        )}
      </Box>
      <StyledContent>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <StyledLastUpdate>
            <Typography variant="bodySmall" color={theme.palette.customColors?.lightGrey}>
              {lastUpdatedTimeTitle}
            </Typography>
            {!showLastHoursInsulin && (
              <StyledTimeValue>
                <Icon icon="check-rounded" size={20} color={theme.palette.primaryCustom[800]} />
                <Typography variant="bodyBig" color={theme.palette.basic[900]}>
                  {dateTimeAgo}
                </Typography>
              </StyledTimeValue>
            )}
          </StyledLastUpdate>
          {showReadingValueAndUnit && (
            <StyledValueContainer>
              <StyledValue>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>{readingUnit}</Typography>
                {readingTrend && <InsulinTrendIcon trend={readingTrend} size={12} />}
              </StyledValue>
            </StyledValueContainer>
          )}
          {showLastHoursInsulin && (
            <StyledValueContainer>
              <StyledValue style={{marginTop: '20px', marginRight: '2rem'}}>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue?.toFixed(2)}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>U/h</Typography>
              </StyledValue>
            </StyledValueContainer>
          )}
          {showLastCarboMeal && (
            <StyledValueContainer>
              <StyledValue style={{marginTop: '20px', marginRight: '2rem'}}>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>g</Typography>
              </StyledValue>
            </StyledValueContainer>
          )}
        </Box>
      </StyledContent>
    </StyledContainer>
  );
};
