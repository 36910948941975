import {useTheme, Typography, Box, Grid, Divider} from '@mui/material';
import {CGMMeasurements, StaticData} from '../../../api';

interface TreatmentSectionProps {
  staticData: StaticData;
  cgmMeasurements?: CGMMeasurements[];
}

export const TreatmentSection = ({staticData}: TreatmentSectionProps): JSX.Element => {
  const theme = useTheme();
  const {basalProfiles, insulinSensitivity, carbohydrateRatios, totalDailyInsulin, a1c} = staticData;

  return (
    <Box sx={{boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
      <Typography variant="h3" color={theme.palette.basic[1000]}>
        Treatment
      </Typography>
      <Grid container spacing={2} mt={1} mb={1}>
        <Grid item xs={12} lg={6}>
          <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
            Last glycosylated hemoglobin test (A1c)
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="h3" color={theme.palette.basic[1000]}>
            {a1c}%
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
        Insulin
      </Typography>
      <Grid container spacing={2} mt={0.5} mb={1}>
        <Grid item xs={12} lg={4}>
          <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
            Total daily insulin (TDI)
          </Typography>
          <Typography variant="h3" color={theme.palette.basic[1000]}>
            {totalDailyInsulin} U
          </Typography>
        </Grid>
      </Grid>
      {basalProfiles[0] && (
        <>
          <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
            Basal profiles
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Grid item xs={12} style={{overflowX: 'auto'}}>
              <table>
                <tbody>
                  <tr>
                    <th style={{width: '5%', textAlign: 'left'}}>
                      <Typography variant="body1" paddingTop={0} color={theme.palette.customColors?.lightGrey}>
                        I
                      </Typography>
                    </th>
                    {basalProfiles[0] &&
                      basalProfiles[0].profile.map(profile => (
                        <td key={'basalProfiles1' + Math.random() + profile} style={{width: '5%'}}>
                          {profile}
                        </td>
                      ))}
                  </tr>
                  <tr>
                    <th style={{width: '5%', textAlign: 'left'}}>
                      <Typography variant="body1" paddingTop={0} color={theme.palette.customColors?.lightGrey}>
                        II
                      </Typography>
                    </th>
                    {basalProfiles[1] &&
                      basalProfiles[1].profile.map(profile => (
                        <td key={'basalProfiles1' + Math.random() + profile} style={{width: '5%'}}>
                          {profile}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Box>
        </>
      )}
      {carbohydrateRatios && (
        <>
          <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
            Carbohydrate Ratios Profile
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Grid item xs={12} style={{overflowX: 'auto'}}>
              <table>
                <tbody>
                  <tr>
                    <th style={{width: '5%', textAlign: 'left'}}>
                      <Typography variant="body1" paddingTop={0} color={theme.palette.customColors?.lightGrey}>
                        I
                      </Typography>
                    </th>
                    {carbohydrateRatios[0] &&
                      carbohydrateRatios[0].ratios.map(ratio => (
                        <td key={'carbohydrateRatio1' + ratio + +Math.random()} style={{width: '5%'}}>
                          {ratio}
                        </td>
                      ))}
                  </tr>
                  <tr>
                    <th style={{width: '5%', textAlign: 'left'}}>
                      <Typography variant="body1" paddingTop={0} color={theme.palette.customColors?.lightGrey}>
                        II
                      </Typography>
                    </th>
                    {carbohydrateRatios[1] &&
                      carbohydrateRatios[1].ratios.map(ratio => (
                        <td key={'carbohydrateRatio1' + ratio + +Math.random()} style={{width: '5%'}}>
                          {ratio}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Box>
        </>
      )}
      {insulinSensitivity && (
        <>
          <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
            Correction factor profile
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Grid item xs={12} style={{overflowX: 'auto'}}>
              <table>
                <tbody>
                  <tr>
                    <th style={{width: '5%', textAlign: 'left'}}>
                      <Typography variant="body1" paddingTop={0} color={theme.palette.customColors?.lightGrey}>
                        I
                      </Typography>
                    </th>
                    {insulinSensitivity[0] &&
                      insulinSensitivity[0].ratios.map(ratio => (
                        <td key={'basalProfiles1' + Math.random() + ratio} style={{width: '5%'}}>
                          {ratio}
                        </td>
                      ))}
                  </tr>
                  <tr>
                    <th style={{width: '5%', textAlign: 'left'}}>
                      <Typography variant="body1" paddingTop={0} color={theme.palette.customColors?.lightGrey}>
                        II
                      </Typography>
                    </th>
                    {insulinSensitivity[1] &&
                      insulinSensitivity[1].ratios.map(ratio => (
                        <td key={'basalProfiles1' + Math.random() + ratio} style={{width: '5%'}}>
                          {ratio}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};
