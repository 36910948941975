import {BoxProps, MenuItem} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Color} from '../../interfaces/styles';

type OpenProps = {
  open: boolean;
};

type IsSelected = {
  isSelected: boolean;
};

type StyledContainerProps = {
  marginval: string;
  background: Color;
};

export const StyledAssignButton = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})<StyledContainerProps & OpenProps>(({theme, marginval, background}) => ({
  width: 'fit-content',
  padding: '3px 12px',
  borderRadius: '100px',
  color: theme.palette.basic[0],
  fontSize: '10px',
  fontWeight: 600,
  lineHeight: '18px',
  textTransform: 'uppercase',
  marginLeft: marginval,
  marginRight: marginval,
  background,
}));

export const StyledPopoverContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '272px',
  padding: '8px 8px 4px',
  gap: '4px',
}));

export const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  display: 'flex',
  borderRadius: '8px',
  padding: '8px 10px',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.basic[800],

  '&:hover': {
    backgroundColor: theme.palette.primaryCustom[50],
  },
}));

export const StyledParticipant = styled('div', {
  shouldForwardProp: prop => prop !== 'isSelected',
})<BoxProps & IsSelected>(({theme, isSelected}) => ({
  display: 'flex',
  width: '210px',
  gap: '6px',
  alignItems: 'center',
  color: isSelected ? theme.palette.primaryCustom[800] : undefined,
}));
