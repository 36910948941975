import {styled} from '@mui/material/styles';

import {Color} from '../../interfaces/styles';

type StyledContainerProps = {
  background: Color;
  width: string;
  height: string;
};

export const StyledContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'width' && prop !== 'height' && prop !== 'background',
})<StyledContainerProps>(({width, height, background}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width,
  height,
  background,
}));
