import {Slide, toast} from 'react-toastify';

import {ToastCard, Icon} from '../../components';
import type {EventResponse} from '../../api/service-types';

import {StyledIconButton} from './styles';

type CustomToastProps = {
  event: EventResponse;
};

export const customToast = ({event}: CustomToastProps) => {
  return toast(<ToastCard event={event} />, {
    position: 'bottom-right',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: 0,
    theme: 'light',
    transition: Slide,
    closeButton: ({closeToast}) => (
      <StyledIconButton onClick={closeToast}>
        <Icon icon="close" size={20} className="Toastify__toast-close" />
      </StyledIconButton>
    ),
  });
};
