export const fieldsErrorMessages = {
  REQUIRED: 'This field is required',
  NOT_VALID: 'This field is not valid',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
  ONE_LOWERCASE: 'One lowercase character (a-z)',
  ONE_UPPERCASE: 'One uppercase character (A-Z)',
  ONE_NUMBER: 'One number (0-9)',
  ONE_SPECIAL: 'One special character (e.g. !@#$%^&*)',
  MINIMUM_LANGS: '8 characters minimum',
};
