import {ENDPOINT_TIME_OPTIONS} from '../../../constants';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {BloodGlucoseChart, CircularProgress, InsulinAndMealsChart} from '../../../components';
import {participantsService} from '../../../api';
import {useParams} from 'react-router-dom';
import {BolusAndTreatmentChart} from '../../../components/charts/bolus-and-treatment/BolusAndTreatment';

export const GraphsWrapper = () => {
  const {id} = useParams();
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    sessionStorage.getItem('sessionTimeFrame') || ENDPOINT_TIME_OPTIONS.THREE_HOURS,
  );
  const [param, setParam] = useState<string>(`${id}?timeframe=${selectedDateRange}`);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const {
    data: graphsData,
    isLoading: isGraphsDataLoading,
    isFetching: isGraphsDataFetching,
    refetch: refetchGraphsDataData,
    isUninitialized: isUninitialized,
  } = participantsService.useGetDynamicScreenDataQuery({
    params: {id: param},
  });

  const refetchTeam = useCallback(() => {
    if (!isUninitialized) {
      refetchGraphsDataData();
    }
  }, [isUninitialized, refetchGraphsDataData]);

  useEffect(() => {
    const handleInterval = () => {
      refetchTeam();
    };

    intervalRef.current = setInterval(handleInterval, 300000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    sessionStorage.setItem('sessionTimeFrame', event?.target?.value as string);
    const sessionTime = sessionStorage.getItem('sessionTimeFrame');
    sessionTime ? setSelectedDateRange(sessionTime) : setSelectedDateRange(event?.target?.value as string);

    setParam(`${id}?timeframe=${event?.target?.value as string}`);
    refetchTeam();
  };

  if (isGraphsDataLoading) {
    return (
      <div style={{width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <Box sx={{width: 200, marginTop: 5}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Time Frame</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedDateRange}
            label="Time Frame"
            onChange={handleChange}>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.HOUR}>Hour</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_HOURS}>3 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.SIX_HOURS}>6 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.DAY}>24 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_DAYS}>3 days</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.WEEK}>Week</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.TWO_WEEKS}>2 weeks</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.MONTH}>Month</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.ALL_TIME}>All time</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {isGraphsDataFetching ? (
        <div style={{width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress size={40} />
        </div>
      ) : (
        graphsData && (
          <>
            <div id="synced-charts">
              <BloodGlucoseChart {...graphsData} />
              <InsulinAndMealsChart {...graphsData} />
              <BolusAndTreatmentChart {...graphsData} />
            </div>
          </>
        )
      )}
    </div>
  );
};
