import {InputAdornment} from '@mui/material';

import {Icon, IconButton, InputField, InputFieldProps} from '../../components';

type SearchProps = InputFieldProps & {
  onSearch?: () => void;
  onReset?: () => void;
};

export const Search = ({onSearch, onReset, value, ...restInputProps}: SearchProps) => {
  return (
    <InputField
      value={value}
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={onSearch}>
              <Icon icon="search" size={16} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton onClick={onReset}>
              <Icon icon="close" size={16} />
            </IconButton>
          </InputAdornment>
        ) : undefined,
        style: {height: '32px', width: '256px'},
      }}
      {...restInputProps}
    />
  );
};
