import {Theme} from '@mui/material/styles';

export const Card = (theme: Theme): Theme['components'] => {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.customShadows.down,
        },
      },
    },
  };
};
