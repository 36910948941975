import axios from 'axios';
import {ENDPOINT_TIME_OPTIONS, httpsUrl} from '../../constants';
import React, {useEffect, useState} from 'react';
import {Participant, ParticipantFilter} from '../../api';
import {StyledTablesContainer} from '../../pages/events/styles';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TablePagination} from '@mui/material';
import {DataTable, TableDataTypeProps} from '../../components';
import {EndpointFormatedType, NoData} from '../../components/data-tables/utils';
import {IsMobile} from '../../utils/IsMobile';

export type DataTableFiltersProps = {
  type: TableDataTypeProps;
  participants?: Participant[];
  participant?: ParticipantFilter;
};

export const DataTableFilters = ({participants, type, participant}: DataTableFiltersProps) => {
  const [responseData, setResponseData] = useState();
  const [currentStart, setCurrentStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [nextStart, setNextStart] = useState(limit);
  const [timeFrame, setTimeFrame] = useState<string>(ENDPOINT_TIME_OPTIONS.WEEK);
  const [selectedParticipant, setSelectedParticipant] = useState<string>(
    participant ? participant.id.toString() : 'All',
  );
  const [page, setPage] = React.useState(0);

  const endPointType = EndpointFormatedType(type);

  useEffect(() => {
    axios
      .get(
        `${httpsUrl}/data-tables/${endPointType}?timeframe=${timeFrame}&start=${currentStart}&limit=${limit}&participantId=${selectedParticipant}`,
      )
      .then(function (response) {
        setResponseData(response.data);
      });
  }, [selectedParticipant, timeFrame, limit, currentStart]);

  const handleChangeTimeFrame = (event: SelectChangeEvent) => {
    setTimeFrame(event?.target?.value as string);
  };
  const handleChangeParticipants = (event: SelectChangeEvent) => {
    setSelectedParticipant(event?.target?.value as string);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage < page) {
      setNextStart(currentStart);
      setCurrentStart(currentStart - limit);
      setPage(newPage);
    } else {
      setCurrentStart(nextStart);
      setNextStart(nextStart + limit);
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(parseInt(event.target.value));
    setNextStart(parseInt(event.target.value));
    setCurrentStart(0);
    setPage(0);
  };

  // @ts-expect-error: totalCount exists but ts do not recognize
  const isDataAvailable = responseData && responseData?.totalCount != 0;
  // @ts-expect-error: totalCount exists but ts do not recognize
  const totalDataTables = responseData ? responseData.totalCount : 0;
  const timeFrameWidth = !IsMobile ? 500 : 'unset';

  return (
    <StyledTablesContainer>
      <Box sx={{width: timeFrameWidth, marginTop: 2, display: 'flex', flexDirection: 'row', gap: '2rem'}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Time Frame</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={timeFrame}
            label="Time Frame"
            onChange={handleChangeTimeFrame}>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.HOUR}>Hour</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_HOURS}>3 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.SIX_HOURS}>6 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.DAY}>24 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_DAYS}>3 days</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.WEEK}>Week</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.TWO_WEEKS}>2 weeks</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.MONTH}>Month</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.ALL_TIME}>All time</MenuItem>
          </Select>
        </FormControl>
        {participants && (
          <FormControl fullWidth>
            <InputLabel id="participants-select-label">Participants</InputLabel>
            <Select
              labelId="participants-select-label"
              id="participants-simple-select"
              value={selectedParticipant}
              label="Participants"
              onChange={handleChangeParticipants}>
              <MenuItem value="-">All</MenuItem>
              {Object.keys(participants).map((participantKey, i) => (
                <MenuItem key={i} value={participants[participantKey as any].id}>
                  {participants[participantKey as any].id} - {participants[participantKey as any].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      {isDataAvailable ? <DataTable responseData={responseData} type={type} /> : <NoData />}
      <TablePagination
        component="div"
        count={isDataAvailable ? totalDataTables : 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledTablesContainer>
  );
};
