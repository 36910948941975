import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';

import unitSlice from './reducers/unitSlice';
import commentsActionSlice from './reducers/commentsActionSlice';
import {
  authService,
  clinicalTrialsService,
  eventsService,
  measurementsService,
  participantsService,
  dataTableService,
  userService,
} from './../api';

export const store = configureStore({
  reducer: {
    // INFO: add every new slice to the function "resetSlices"
    unit: unitSlice,
    commentsAction: commentsActionSlice,
    // INFO: add every new service to the function "resetServices"
    [authService.reducerPath]: authService.reducer,
    [clinicalTrialsService.reducerPath]: clinicalTrialsService.reducer,
    [eventsService.reducerPath]: eventsService.reducer,
    [measurementsService.reducerPath]: measurementsService.reducer,
    [participantsService.reducerPath]: participantsService.reducer,
    [dataTableService.reducerPath]: dataTableService.reducer,
    [userService.reducerPath]: userService.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      authService.middleware,
      clinicalTrialsService.middleware,
      eventsService.middleware,
      measurementsService.middleware,
      participantsService.middleware,
      userService.middleware,
      dataTableService.middleware,
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
