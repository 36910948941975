import {IconButton, InputAdornment, Typography} from '@mui/material';
import {useState} from 'react';

import {Icon, InputField, InputFieldProps, InlineLink} from '../../components';

import {StyledForgotContainer} from './styles';

type InputPasswordFieldProps = InputFieldProps & {
  withForgot?: boolean;
};

export const InputPasswordField = ({withForgot = false, label, ...restProps}: InputPasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputField
      label={withForgot ? <Forgot label={label} /> : label}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon icon="password" size={17} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
              <Icon icon={showPassword ? 'eye-open' : 'eye-closed'} size={17} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};

type ForgotProps = {
  label: React.ReactNode;
};

const Forgot = ({label}: ForgotProps) => {
  return (
    <StyledForgotContainer>
      <Typography variant="captionSmall">{label}</Typography>
      <InlineLink to="/auth/forgot">Forgot password?</InlineLink>
    </StyledForgotContainer>
  );
};
