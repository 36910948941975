import {TableDataTypeProps} from '../../components';

const cgmMeasurementsHeadCells: string[] = [
  'Id',
  'Participant Id',
  'Priority',
  'Status',
  'Value',
  'Trend',
  'Comment Thread Id',
  'Assigned User Id',
  'Control Mode',
  'Event Local Date Time',
  'Event UTC Instant',
];

const controllerStatedChangeHeadCells: string[] = [
  'Id',
  'Assigned User Id',
  'Comment Thread Id',
  'Controller Id',
  'Event Local Date Time',
  'Event UTC Instant',
  'From Mode',
  'Message',
  'Participant Id',
  'Priority',
  'Status',
  'To Mode',
];

const controllerEventChangeHeadCells: string[] = [
  'Id',
  'Assigned User Id',
  'Comment Thread Id',
  'Controller Id',
  'Event Local Date Time',
  'Event UTC Instant',
  'Exception',
  'Message',
  'Participant Id',
  'Priority',
  'Status',
];

const glucometerMeasurementsHeadCells: string[] = [
  'Id',
  'Assigned User Id',
  'Comment Thread Id',
  'Participant Id',
  'Priority',
  'Value',
  'Event Local Date Time',
  'Event UTC Instant',
  'IsCalibration',
  'Status',
];

const insulinComputedHeadCells: string[] = [
  'Id',
  'Assigned User Id',
  'Comment Thread Id',
  'Participant Id',
  'Priority',
  'Amount',
  'Event Date Time',
  'Status',
  'Backfill Timestamp',
  'Delivery Status',
  'Event Local Date Time',
  'Injection Type',
  'Passed Safety Net',
  'Status Updated Local Time',
  'Status Updated UTC',
  'Unit',
];

export const HeadCells = (type: TableDataTypeProps): string[] => {
  switch (type) {
    case 'cgmMeasurements':
      return cgmMeasurementsHeadCells;

    case 'controllerStatedChange':
      return controllerStatedChangeHeadCells; // TODO: when trend is zero should not be null in the UI

    case 'controllerEventChange':
      return controllerEventChangeHeadCells;

    case 'glucometerMeasurements':
      return glucometerMeasurementsHeadCells;

    case 'insulinComputed':
      return insulinComputedHeadCells;
  }
};
