import {TableDataTypeProps} from '../../components';

const cgmMeasurementsBodyCells: string[] = [
  'id',
  'participantId',
  'priority',
  'status',
  'value',
  'trend',
  'commentThreadId',
  'assignedUserId',
  'controlMode',
  'eventLocalDateTime',
  'eventUTCInstant',
];

const controllerStatedChangeBodyCells: string[] = [
  'id',
  'assignedUserId',
  'commentThreadId',
  'controllerId',
  'eventLocalDateTime',
  'eventUTCInstant',
  'fromMode',
  'message',
  'participantId',
  'priority',
  'status',
  'toMode',
];

const controllerEventChangeBodyCells: string[] = [
  'id',
  'assignedUserId',
  'commentThreadId',
  'controllerId',
  'eventLocalDateTime',
  'eventUTCInstant',
  'exception',
  'message',
  'participantId',
  'priority',
  'status',
];

const glucometerMeasurementsBodyCells: string[] = [
  'id',
  'assignedUserId',
  'commentThreadId',
  'participantId',
  'priority',
  'value',
  'eventLocalDateTime',
  'eventUTCInstant',
  'isCalibration',
  'status',
];

const insulinComputedBodyCells: string[] = [
  'id',
  'assignedUserId',
  'commentThreadId',
  'participantId',
  'priority',
  'amount',
  'eventUTCInstant',
  'status',
  'backfillTimestamp',
  'deliveryStatus',
  'eventLocalDateTime',
  'injectionType',
  'passedSafetyNet',
  'statusUpdatedLocalTime',
  'statusUpdatedUTCInstant',
  'unit',
];

export const BodyCells = (type: TableDataTypeProps): string[] => {
  switch (type) {
    case 'cgmMeasurements':
      return cgmMeasurementsBodyCells;
      break;

    case 'controllerStatedChange':
      return controllerStatedChangeBodyCells;
      break;

    case 'controllerEventChange':
      return controllerEventChangeBodyCells;
      break;

    case 'glucometerMeasurements':
      return glucometerMeasurementsBodyCells;
      break;

    case 'insulinComputed':
      return insulinComputedBodyCells;
      break;
  }
};
