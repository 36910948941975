import {useTheme, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {inflect} from 'inflection';

import {ParticipantCard, Tag, CircularProgress} from '../../components';
import {participantsService, clinicalTrialsService, measurementsService} from '../../api';
import {pollingInterval} from '../../constants';
import {getClinicalTrialStatus} from '../../helpers';

import {StyledContainer, StyledTitleContainer, StyledTitleContent, StyledContent} from './styles';

export const ClinicalTrialPage = (): JSX.Element => {
  const theme = useTheme();
  const {id} = useParams();

  if (!id) throw new Error('clinical trial id is undefined, unhandled error');

  const {
    data: participantsByClinicalTrialId,
    isLoading: areParticipantsByClinicalTrialIdLoading,
    error: participantsByClinicalTrialIdError,
    refetch: refetchParticipantsByClinicalTrialId,
  } = participantsService.useGetParticipantsByClinicalTrialIdQuery(
    {
      params: {id},
    },
    {pollingInterval},
  );

  const {
    data: latestCgmMeasurementsByClinicalTrialId,
    isLoading: areLatestCgmMeasurementsByClinicalTrialIdLoading,
    error: latestCgmMeasurementsByClinicalTrialIdError,
    refetch: refetchLatestCgmMeasurementsByClinicalTrialId,
  } = measurementsService.useGetLatestCgmMeasurementsByClinicalTrialIdQuery(
    {
      params: {id},
    },
    {pollingInterval},
  );

  const {
    data: clinicalTrial,
    isLoading: isClinicalTrialLoading,
    error: clinicalTrialError,
    refetch: refetchClinicalTrial,
  } = clinicalTrialsService.useGetClinicalTrialByIdQuery({params: {id}});

  const onRefetch = async () => {
    refetchParticipantsByClinicalTrialId();
    refetchLatestCgmMeasurementsByClinicalTrialId();
    refetchClinicalTrial();
  };

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (
    areParticipantsByClinicalTrialIdLoading ||
    areLatestCgmMeasurementsByClinicalTrialIdLoading ||
    isClinicalTrialLoading
  ) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (participantsByClinicalTrialIdError || latestCgmMeasurementsByClinicalTrialIdError || clinicalTrialError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  if (!participantsByClinicalTrialId || !latestCgmMeasurementsByClinicalTrialId || !clinicalTrial) {
    throw new Error(
      'participantsByClinicalTrialId, latestCgmMeasurementsByClinicalTrialId or clinicalTrial are undefined, unhandled state',
    );
  }

  const participantsNumber = participantsByClinicalTrialId.length;
  const hasParticipants = participantsNumber > 0;
  const pageTitle = `Clinical trial #${id}`;
  const pageSubtitle = `${participantsNumber} ${inflect('participant', participantsNumber)}`;
  const clinicalTrialStatus = getClinicalTrialStatus(clinicalTrial);

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitleContent>
          <Typography variant="h2" color={theme.palette.basic[1000]}>
            {pageTitle}
          </Typography>
          <Tag status={clinicalTrialStatus} />
        </StyledTitleContent>
        <Typography variant="bodySmall" color={theme.palette.basic[800]}>
          {pageSubtitle}
        </Typography>
      </StyledTitleContainer>
      {hasParticipants ? (
        <StyledContent>
          {participantsByClinicalTrialId.map(participant => {
            const measurement = latestCgmMeasurementsByClinicalTrialId.find(
              measurement => measurement.participantId === participant.id,
            );

            return <ParticipantCard key={participant.id} participant={participant} measurement={measurement} />;
          })}
        </StyledContent>
      ) : (
        /* TODO: This is a temporary solution. We have to discuss and choose some solution */
        <div>No Participants to display</div>
      )}
    </StyledContainer>
  );
};
