import {DynamicData} from '../../../api';
import {useRef, useEffect, useState} from 'react';
import {scaleLinear, scaleTime} from '@visx/scale';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import {mmolPerLToMgPerdL} from '../../../helpers';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import moment from 'moment';
import {timeSampConvert} from '../utils';

interface DataPoint {
  x: string;
  y: number;
}

export const BloodGlucoseChart = ({cgmMeasurements}: DynamicData): JSX.Element => {
  const {unit} = useAppSelector(unitSelector);
  const convertToMmol = (value: number) => {
    return unit == 'mmol/L' ? value / 18.18 : value;
  };

  const ranges = [
    {min: 0, max: convertToMmol(54), color: '#940106'},
    {min: convertToMmol(54), max: convertToMmol(70), color: '#FF1C01'},
    {min: convertToMmol(70), max: convertToMmol(180), color: '#47D120'},
    {min: convertToMmol(180), max: convertToMmol(250), color: '#F4BC1C'},
    {min: convertToMmol(250), max: convertToMmol(400), color: '#FF9D00'},
  ];

  // Process glucose data
  const dataCombined: DataPoint[] = cgmMeasurements.map(cgm => ({
    x: timeSampConvert(cgm.eventUTCInstant),
    y: mmolPerLToMgPerdL(cgm.measurement, unit),
  }));

  const minX = Math.min(...dataCombined.map(d => new Date(d.x).getTime()));
  const maxX = Math.max(...dataCombined.map(d => new Date(d.x).getTime()));
  const minY = 0;
  const maxY = convertToMmol(400);

  // State for dynamic width
  const svgRef = useRef<SVGSVGElement>(null);
  const [width, setWidth] = useState(1000); // Default width

  // State for tooltip and vertical line
  const [tooltipData, setTooltipData] = useState<DataPoint | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{x: number; y: number} | null>(null);
  const [mouseX, setMouseX] = useState<number | null>(null);

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};

  // Scales
  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });

  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  // Find nearest data point based on mouse position
  const handleMouseMove = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    const {clientX} = event;
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const mouseXPosition = clientX - svgRect.left;

    const closestDatum = dataCombined.reduce((prev, curr) => {
      const prevDistance = Math.abs(xScale(new Date(prev.x)) - mouseXPosition);
      const currDistance = Math.abs(xScale(new Date(curr.x)) - mouseXPosition);
      return currDistance < prevDistance ? curr : prev;
    });

    setMouseX(mouseXPosition);
    setTooltipData(closestDatum);
    setTooltipPosition({
      x: xScale(new Date(closestDatum.x)),
      y: yScale(closestDatum.y),
    });
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
    setTooltipPosition(null);
    setMouseX(null);
  };

  // Render scatter points
  const renderCircles = (data: DataPoint[]) =>
    data.map((d, i) => (
      <circle
        key={`circle-${i}`}
        cx={xScale(new Date(d.x))}
        cy={yScale(d.y)}
        r={3}
        fill={ranges.find(range => d.y >= range.min && d.y < range.max)?.color || 'gray'}
        onMouseMove={() => {
          setTooltipData(d);
          setTooltipPosition({
            x: xScale(new Date(d.x)),
            y: yScale(d.y),
          });
        }}
        onMouseLeave={() => {
          if (tooltipData === d) {
            setTooltipData(null);
            setTooltipPosition(null);
          }
        }}
      />
    ));

  return (
    <div style={{position: 'relative'}}>
      <svg ref={svgRef} width="100%" height={height}>
        <Group>
          {/* Draw grid */}
          <GridColumns scale={xScale} height={height - margin.bottom} strokeDasharray="2,2" left={margin.left} />
          <GridRows scale={yScale} width={width - margin.right} strokeDasharray="2,2" top={margin.top} />

          {/* Draw axes */}
          <AxisBottom
            top={height - margin.bottom}
            scale={xScale}
            tickLabelProps={() => ({
              fill: 'black',
              fontSize: 11,
              textAnchor: 'middle',
            })}
          />
          <AxisLeft
            scale={yScale}
            left={margin.left}
            tickLabelProps={() => ({
              fill: 'black',
              fontSize: 11,
              textAnchor: 'end',
            })}
          />

          {/* Draw vertical crosshair */}
          {mouseX !== null && (
            <line
              x1={mouseX}
              x2={mouseX}
              y1={margin.top}
              y2={height - margin.bottom}
              stroke="gray"
              strokeDasharray="4,4"
            />
          )}

          {/* Draw scatter points */}
          {renderCircles(dataCombined)}

          {/* Capture mouse events */}
          <rect
            x={margin.left}
            y={margin.top}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
            fill="transparent"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </Group>
      </svg>

      {/* Tooltip */}
      {tooltipData && tooltipPosition && (
        <div
          style={{
            position: 'absolute',
            left: `${tooltipPosition.x - 150}px`,
            top: `${tooltipPosition.y - 20}px`,
            backgroundColor: 'white',
            padding: '10px',
            pointerEvents: 'none',
            transform: 'translate(-50%, -100%)',
            boxShadow: '1px 1px 1px 1px #757575',
            borderRadius: '4px',
          }}>
          <h4>Glucose Level</h4>
          <p>X: {moment(tooltipData.x).format('DD-MM-YYYY HH:mm:ss')}</p>
          <p>Y: {tooltipData.y + ' ' + unit}</p>
        </div>
      )}
    </div>
  );
};
