import {Box, Drawer} from '@mui/material';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {DASHBOARD} from '../../../config';
import {useResponsive} from '../../../hooks';

import {NavBarContent} from './NavBarContent';

const NAV_WIDTH = DASHBOARD.NAV_WIDTH;

type NavProps = {
  openNav: boolean;
  onCloseNav: () => void;
};

export const NavBar = ({openNav, onCloseNav}: NavProps) => {
  const {pathname} = useLocation();

  const isDesktop = useResponsive({query: 'up', start: 'lg'});

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: {lg: NAV_WIDTH},
      }}>
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
            },
          }}>
          <NavBarContent />
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {width: NAV_WIDTH},
          }}>
          <NavBarContent />
        </Drawer>
      )}
    </Box>
  );
};
