import {createApi, BaseQueryFn} from '@reduxjs/toolkit/query/react';

import {httpsUrl} from '../../constants';
import {baseQuery} from '../../helpers';
import type {Login, LoginMutation, PasswordEmail} from '../service-types';
import {EmailParam} from '../../api';

const authApiBuilder = (baseQuery: BaseQueryFn) =>
  createApi({
    reducerPath: 'auth-service',
    baseQuery,
    endpoints: builder => ({
      login: builder.mutation<Login, {body: LoginMutation}>({
        query: ({body}) => ({
          url: '/authentication/login',
          method: 'POST',
          body,
        }),
      }),
      forgotPassword: builder.query<PasswordEmail, {params: EmailParam}>({
        query: ({params}) => ({
          url: `/authentication/reset-password/send-email/${params.email}`,
          method: 'GET',
        }),
      }),
    }),
  });

export const authService = authApiBuilder(baseQuery(httpsUrl));
