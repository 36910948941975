import {BaseQueryFn, createApi} from '@reduxjs/toolkit/dist/query/react';

import {
  GlucoseParam,
  IdParams,
  TableCGMMeasurementsResponse,
  TableControllerEventResponse,
  TableGlucometerMeasurementsResponse,
  TableInsulinBasalResponse,
  TableInsulinBoluses,
  TableInsulinComputedResponse,
  TableControllerStateChangedResponse,
} from '../../api';
import {baseQuery} from '../../helpers';
import {httpsUrl} from '../../constants';

const dataTablesApiBuilder = (baseQuery: BaseQueryFn) =>
  createApi({
    reducerPath: 'data-tables',
    tagTypes: ['TABLE'],
    baseQuery,
    endpoints: builder => ({
      getTablesCgmMeasurement: builder.query<TableCGMMeasurementsResponse, void>({
        query() {
          return {
            url: `/data-tables/cgm-measurements?timeframe=all&limit=1000`,
            method: 'GET',
          };
        },
      }),
      getTablesInsulinBoluses: builder.query<TableInsulinBoluses, void>({
        query() {
          return {
            url: `/data-tables/insulin-boluses?timeframe=all&limit=1000`,
            method: 'GET',
          };
        },
      }),
      getTablesGlucometerMeasurements: builder.query<TableGlucometerMeasurementsResponse, void>({
        query() {
          return {
            url: `/data-tables/glucometer-measurements?timeframe=all&limit=1000`,
            method: 'GET',
          };
        },
      }),
      getTablesInsulinComputed: builder.query<TableInsulinComputedResponse, void>({
        query() {
          return {
            url: `/data-tables/insulin-computed?timeframe=all&limit=1000`,
            method: 'GET',
          };
        },
      }),
      getTablesInsulinBasal: builder.query<TableInsulinBasalResponse, void>({
        query() {
          return {
            url: `/data-tables/insulin-basal?timeframe=all&limit=1000`,
            method: 'GET',
          };
        },
      }),
      getTablesControllerEvent: builder.query<TableControllerEventResponse, void>({
        query() {
          return {
            url: `/data-tables/controller-event?timeframe=all&limit=1000`,
            method: 'GET',
          };
        },
      }),
      getTablesControllerStateChanged: builder.query<TableControllerStateChangedResponse, void>({
        query() {
          return {
            url: `/data-tables/controller-state-changed?timeframe=all&limit=1000`,
            method: 'GET',
          };
        },
      }),

      // query by param
      getTablesGlucoseReadings: builder.query<TableCGMMeasurementsResponse, {params: GlucoseParam}>({
        query({params}) {
          return {
            url: `/data-tables/cgm-measurements?${params.param}&timeframe=all&limit=1000`,
            method: 'GET',
          };
        },
      }),
      getTablesCgmMeasurementById: builder.query<TableCGMMeasurementsResponse, {params: IdParams}>({
        query({params}) {
          return {
            url: `/data-tables/cgm-measurements?participantId=${params.id}&timeframe=all&limit=1000`,
            method: 'GET',
          };
        },
      }),
      getTablesInsulinComputedById: builder.query<TableCGMMeasurementsResponse, {params: IdParams}>({
        query({params}) {
          return {
            url: `/data-tables/insulin-computed?participantId=${params.id}&timeframe=all&limit=1000`,

            method: 'GET',
          };
        },
      }),
      getTablesInsulinBolusesById: builder.query<TableCGMMeasurementsResponse, {params: IdParams}>({
        query({params}) {
          return {
            url: `/data-tables/insulin-boluses?participantId=${params.id}&timeframe=all&limit=1000`,

            method: 'GET',
          };
        },
      }),
      getTablesInsulinBasalById: builder.query<TableCGMMeasurementsResponse, {params: IdParams}>({
        query({params}) {
          return {
            url: `/data-tables/insulin-basal?participantId=${params.id}&timeframe=all&limit=1000`,

            method: 'GET',
          };
        },
      }),
    }),
  });

export const dataTableService = dataTablesApiBuilder(baseQuery(httpsUrl));
