import {Container, Typography} from '@mui/material';

import {CreateNewPasswordForm, SuccessfulPassword} from '../../../sections';
import {useBoolean} from '../../../hooks';

import {StyledContent} from './styles';

export const CreateNewPasswordPage = () => {
  const {value: isPasswordChangedSuccessfully, setTrue: passwordChangedSuccessfully} = useBoolean();

  return (
    <Container maxWidth="sm">
      {isPasswordChangedSuccessfully ? (
        <SuccessfulPassword />
      ) : (
        <StyledContent>
          <Typography variant="h1" gutterBottom>
            Create a new password
          </Typography>

          <CreateNewPasswordForm passwordChangedSuccessfully={passwordChangedSuccessfully} />
        </StyledContent>
      )}
    </Container>
  );
};
