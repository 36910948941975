import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 8px',
  gap: '12px',
}));

export const StyledUnitsContainer = styled('div')(({theme}) => ({
  borderRadius: '12px',
  padding: '24px',
  background: theme.palette.basic[0],
  gap: '24px',
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledSwitchContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));
