import {Box, Divider, MenuItem, Popover, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {Avatar} from '../../../components';
import {getFullName, getInitials} from '../../../helpers';
import {usePopover} from '../../../hooks';
import {useAppDispatch} from '../../../store';
import {resetServices, resetSlices} from '../../../store/actions';
import {userService, wsService} from '../../../api';

import {StyledIconButton} from './styles';
import {StyledNavItem} from '../nav/styles';

export const AccountPopover = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userId = localStorage.getItem('userId');
  if (!userId) throw new Error('userId is undefined, unhandled error');
  const {data: user} = userService.useGetUserByIdQuery({params: {id: userId}});
  if (!user) throw new Error('user is undefined, unhandled error');

  const {open, handleOpen, handleClose} = usePopover<HTMLButtonElement>();

  const {firstName, lastName, loginEmail} = user;
  const initials = getInitials({firstName, lastName});
  const displayName = getFullName({firstName, lastName});

  const resetLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
  };

  const logout = () => {
    handleClose();
    wsService.disconnect();
    resetLocalStorage();
    dispatch(resetServices());
    dispatch(resetSlices());
    navigate('/auth/login');
  };

  return (
    <>
      <StyledIconButton onClick={handleOpen}>
        {/* TODO: add src when it'll be on the back-end */}
        <Avatar initials={initials} size="lg" typographyVariant="bodyBig" src={null} />
      </StyledIconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}>
        <Box sx={{my: 1.5, px: 2.5}} display="flex" flexDirection="row" gap="9px">
          {/* TODO: add src when it'll be on the back-end */}
          <Avatar initials={initials} size="lg" typographyVariant="bodyBig" src={null} />
          <Box display="flex" flexDirection="column">
            <Typography variant="bodyBigSemibold">{displayName}</Typography>
            <Typography variant="bodySmall" sx={{color: 'text.secondary'}} noWrap>
              {loginEmail}
            </Typography>
          </Box>
        </Box>

        <Divider />
        <StyledNavItem to={'/dashboard/settings'} sx={{m: 1}} hasChildren={true} nestingLevel={0}>
          <Typography variant="bodyBig">Settings</Typography>
        </StyledNavItem>
        <MenuItem onClick={logout} sx={{m: 1}}>
          <Typography variant="bodyBig">Logout</Typography>
        </MenuItem>
      </Popover>
    </>
  );
};
