import {
  FormControl,
  InputLabel,
  InputLabelProps,
  MenuItem,
  OutlinedInput,
  OutlinedInputProps,
  styled,
} from '@mui/material';

export const StyledFormControl = styled(FormControl)(({theme}) => ({
  backgroundColor: theme.palette.basic[50],
  borderRadius: '8px',

  '& > .MuiInputBase-formControl': {
    padding: 0,
    height: '32px',
  },

  '&:has([aria-expanded="true"])': {
    backgroundColor: theme.palette.primaryCustom[50],

    '& .MuiSelect-icon': {
      color: theme.palette.primaryCustom[1000],
    },

    '& .MuiInputLabel': {
      color: theme.palette.primaryCustom[1000],
    },
    '& .Mui-focused': {
      color: theme.palette.primaryCustom[1000],
    },
  },
}));

type HasSelected = {
  hasSelected: boolean;
};

export const StyledInputLabel = styled(InputLabel, {
  shouldForwardProp: prop => prop !== 'hasSelected',
})<InputLabelProps & HasSelected>(({theme, hasSelected}) => ({
  top: '50%',
  left: '15px',
  transform: 'translate(0px, -50%)',
  color: theme.palette.basic[800],
  display: hasSelected ? 'none' : 'block',
  pointerEvents: 'none',

  '&.Mui-focused': {
    color: theme.palette.basic[800],
  },
}));

export const StyledOutlinedInput = styled(OutlinedInput, {
  shouldForwardProp: prop => prop !== 'hasSelected',
})<OutlinedInputProps & HasSelected>(({theme, hasSelected}) => ({
  color: theme.palette.primaryCustom[1000],
  backgroundColor: hasSelected ? theme.palette.primaryCustom[50] : 'transparent',

  '&:has([aria-expanded="true"])': {
    border: '1px solid',
    borderRadius: '8px',
    borderColor: theme.palette.primaryCustom[1000],
  },

  '& .MuiSelect-icon': {
    color: hasSelected ? theme.palette.primaryCustom[1000] : theme.palette.basic[800],
  },

  '& > fieldset': {
    border: 'none',
  },

  '&:hover': {
    backgroundColor: theme.palette.primaryCustom[50],
  },
  '&:focus': {
    color: theme.palette.primaryCustom[1000],
  },
}));

export const StyledPopoverContent = styled('div')(() => ({
  width: 'fit-content',
  minWidth: '270px',
  padding: '8px',
}));

export const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  padding: '0',
  paddingRight: '12px',
  borderRadius: '8px',

  '&:hover': {
    backgroundColor: theme.palette.primaryCustom[50],
  },
}));

export const StyledClearButton = styled('div')(({theme}) => ({
  padding: '10px 14px',
  paddingRight: '12px',
  color: theme.palette.primaryCustom[800],
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  '&:hover': {
    cursor: 'pointer',
  },
}));
