import {Tabs, Box} from '@mui/material';
import {styled} from '@mui/material/styles';

export const StyledTabsBox = styled(Box)(({theme}) => ({
  color: theme.palette.primaryCustom[900],
  fontSize: '14px',
}));

export const StyledTabs = styled(Tabs)(({theme}) => ({
  '.Mui-selected': {
    color: `${theme.palette.primaryCustom[800]}!important`,
  },
}));
