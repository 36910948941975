import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {Stack} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';

import {InputPasswordField} from '../../../components';
import {httpsUrl, passwordRequirements} from '../../../constants';
import {CreateNewPasswordData} from '../../../interfaces/auth';
import {getValidationSchema} from '../../../utils';

import {PasswordIndicator} from './PasswordIndicator';
import {useParams} from 'react-router-dom';
import axios from 'axios';

type CreateNewPasswordFormProps = {
  passwordChangedSuccessfully: () => void;
};

export const CreateNewPasswordForm = ({passwordChangedSuccessfully}: CreateNewPasswordFormProps): JSX.Element => {
  const {createNewPasswordValidationSchema} = getValidationSchema();
  const {id} = useParams();

  const {
    control,
    handleSubmit,
    formState: {errors, isDirty},
  } = useForm<CreateNewPasswordData>({
    resolver: yupResolver(createNewPasswordValidationSchema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      new: '',
      repeat: '',
    },
  });

  const onSubmitHandler = (e: {new: string}) => {
    axios
      .patch(`${httpsUrl}/authentication/reset-password`, {
        uuid: id,
        newPassword: e.new as string,
      })
      .then(response => console.info(response.data))
      .catch(error => console.error(error));

    passwordChangedSuccessfully();
  };

  return (
    <>
      <Stack spacing={1} sx={{my: 2}}>
        <Controller
          control={control}
          name="new"
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <InputPasswordField
              name="new"
              label="New password"
              placeholder="Password"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              errorMessage={error?.type === 'required' ? error.message : ''}
            />
          )}
        />
        <Stack spacing={0.5} sx={{my: 2}}>
          {passwordRequirements.map(requirement => {
            const isError = !isDirty ? true : !!(errors.new?.types?.[requirement.name] || false);
            return <PasswordIndicator key={requirement.name} title={requirement.message} isError={isError} />;
          })}
        </Stack>
      </Stack>

      <Stack sx={{my: 2}}>
        <Controller
          control={control}
          name="repeat"
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <InputPasswordField
              name="repeat"
              label="Repeat password"
              placeholder="Password"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              errorMessage={error?.message}
            />
          )}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit(onSubmitHandler)}
        disableFocusRipple>
        Save password
      </LoadingButton>
    </>
  );
};
