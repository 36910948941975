import {fieldsErrorMessages} from './fields-error-messages';

const {MINIMUM_LANGS, ONE_UPPERCASE, ONE_LOWERCASE, ONE_NUMBER, ONE_SPECIAL} = fieldsErrorMessages;

export type PasswordErrorTypes =
  | 'required'
  | 'min'
  | 'oneLowercase'
  | 'oneUppercase'
  | 'oneNumber'
  | 'oneSpecialCharacter'
  | 'onlyLatin'
  | 'noSpaces';

export type PasswordRequirement = {name: PasswordErrorTypes; message: string};

export const passwordRequirements: PasswordRequirement[] = [
  {name: 'oneLowercase', message: ONE_LOWERCASE},
  {name: 'oneUppercase', message: ONE_UPPERCASE},
  {name: 'oneNumber', message: ONE_NUMBER},
  {name: 'oneSpecialCharacter', message: ONE_SPECIAL},
  {name: 'min', message: MINIMUM_LANGS},
];
