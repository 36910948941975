import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 8px',
  gap: '12px',
  height: 'auto',
  margin: '25px 0px',
}));
