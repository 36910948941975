import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../store';

interface CommentsActionState {
  toggleActionPerformed: boolean;
  isEventDetailsDialogOpen: boolean;
}

const initialState: CommentsActionState = {
  toggleActionPerformed: false,
  isEventDetailsDialogOpen: false,
};

export const commentsActionSlice = createSlice({
  name: 'commentsAction',
  initialState,
  reducers: {
    setToggleActionPerformed: state => {
      state.toggleActionPerformed = !state.toggleActionPerformed;
    },
    setEventDetailsDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEventDetailsDialogOpen = action.payload;
    },
  },
});

export const {setToggleActionPerformed, setEventDetailsDialogOpen} = commentsActionSlice.actions;
export const commentsActionSelector = (state: RootState) => state.commentsAction;
export default commentsActionSlice.reducer;
