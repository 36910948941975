import {styled} from '@mui/material/styles';

import {IconButton} from '../../components';

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '2px',
  right: '2px',
}));

export const StyledToast = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));
