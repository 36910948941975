import {IconButton, IconButtonProps, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';

type OpenProps = {
  open: boolean;
};
export const StyledCommentButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'open',
})<IconButtonProps & OpenProps>(({theme, open}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '6px',
  marginLeft: '-8px',
  color: open ? theme.palette.primaryCustom[1000] : theme.palette.basic[800],

  '&.Mui-disabled': {
    color: theme.palette.basic[800],
  },
}));

export const StyledPopoverContent = styled('div')(() => ({
  width: 'fit-content',
  minWidth: '270px',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  padding: '12px 14px',

  '& .MuiInput-underline:before': {
    display: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 0,
  },
}));

export const StyledActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 14px',
}));

export const StyledCancelButton = styled(IconButton)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '6px',
  color: theme.palette.primaryCustom[1000],
}));

export const StyledSendButton = styled(IconButton)(({theme}) => ({
  width: '32px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '6px',
  backgroundColor: theme.palette.primaryCustom[1000],
  '&:hover': {
    backgroundColor: theme.palette.primaryCustom[800],
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.basic[200],
  },
}));
