import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {changeFormateDate, changeFormateDateUTC} from '../../utils';
import {Tag} from '../../components';
import {TableResponse} from '../../api';
import {isBoolean} from '../../components/data-tables/utils';
import {HeadCells} from '../../components/data-tables/DataTableHeadData';
import {BodyCells} from '../../components/data-tables/DataTableBodyData';
import {mmolPerLToMgPerdL} from '../../helpers';
import {useAppSelector} from '../../store';
import {unitSelector} from '../../store/reducers/unitSlice';

export type TableDataTypeProps =
  | 'cgmMeasurements'
  | 'controllerStatedChange'
  | 'controllerEventChange'
  | 'glucometerMeasurements'
  | 'insulinComputed';

export type TableDataProps = {
  responseData: TableResponse;
  type: TableDataTypeProps;
};

export const DataTable = ({responseData, type}: TableDataProps) => {
  const {data} = responseData;
  const {unit} = useAppSelector(unitSelector);

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <>
          <TableHead>
            <TableRow>
              {HeadCells(type).map(value => (
                <TableCell key={value + 'key'} align="center">
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(data => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={'key-' + data.id + data.bodyCell}>
                  {BodyCells(type).map(bodyCell => {
                    if (bodyCell == 'status') {
                      return (
                        <TableCell key={data[bodyCell] + 'key' + data.id} align="center">
                          {data[bodyCell] ? <Tag marginval="auto" status={data[bodyCell]} /> : 'null'}
                        </TableCell>
                      );
                    } else if (bodyCell == 'eventLocalDateTime' || bodyCell == 'statusUpdatedLocalTime') {
                      return (
                        <TableCell key={data[bodyCell] + 'key' + data.id} align="center">
                          {data[bodyCell] ? changeFormateDate(data[bodyCell]) : 'null'}
                        </TableCell>
                      );
                    } else if (bodyCell.includes('Time') || bodyCell.includes('UTC')) {
                      return (
                        <TableCell key={data[bodyCell] + 'key' + data.id} align="center">
                          {data[bodyCell] ? changeFormateDateUTC(data[bodyCell]) : 'null'}
                        </TableCell>
                      );
                    } else if (type == 'cgmMeasurements' && bodyCell == 'value') {
                      return (
                        <TableCell key={data[bodyCell] + 'key' + data.id} align="center">
                          {data[bodyCell] ? mmolPerLToMgPerdL(data[bodyCell] ?? 0, unit) : 'null'}
                        </TableCell>
                      );
                    } else if (isBoolean(data[bodyCell])) {
                      return (
                        <TableCell key={data[bodyCell] + 'key' + data.id} align="center">
                          {data[bodyCell] == true ? 'true' : 'false'}
                        </TableCell>
                      );
                    } else
                      return (
                        <TableCell key={data[bodyCell] + 'key' + data.id} align="center">
                          {data[bodyCell] ? data[bodyCell] : 'null'}
                        </TableCell>
                      );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </>
      </Table>
    </TableContainer>
  );
};
