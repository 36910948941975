import {styled} from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const StyledToggleBtnGroup = styled(ToggleButtonGroup)(({theme}) => ({
  height: '32px',
  '& .MuiToggleButton-root.Mui-selected': {
    color: theme.palette.basic[1000],
    backgroundColor: theme.palette.primaryCustom[50],
    fontWeight: theme.typography.fontWeightMedium,
    border: `1.5px solid ${theme.palette.primaryCustom[800]}`,
  },
}));
