import {IconButtonProps as MuiIconButtonProps} from '@mui/material';

import {StyledIconButton} from './styles';

export type IconButtonProps = MuiIconButtonProps;

export const IconButton = ({children, ...props}: IconButtonProps): JSX.Element => {
  return (
    <StyledIconButton {...props} disableFocusRipple disableRipple>
      {children}
    </StyledIconButton>
  );
};
