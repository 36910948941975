import {MutableRefObject} from 'react';
import {useTheme, Typography, Box, Grid, Divider} from '@mui/material';

import {StyledContainer} from './styles';
import {DynamicData, StaticData} from '../../../api';
import moment from 'moment';
import {TreatmentSection} from '../../../sections/participant/treatment';

interface ParticipantDetailsSectionProps {
  participantDetailsRef: MutableRefObject<HTMLDivElement | null>;
  staticData: StaticData;
  dynamicData: DynamicData;
}

export const ParticipantDetailsSection = ({
  participantDetailsRef,
  staticData,
  dynamicData,
}: ParticipantDetailsSectionProps): JSX.Element => {
  const {cgmMeasurements, devices} = dynamicData;

  const {birthDate, clinicalTrial, diabetesFoundDate, gender, heightInCm, therapyStartDate, weightInKg} = staticData;

  const theme = useTheme();

  const changeFormateDate = (oldDate: string) => {
    return moment(oldDate, 'YYYY/MM/DD').format('DD/MM/YYYY');
  };

  return (
    <StyledContainer ref={participantDetailsRef}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <TreatmentSection staticData={staticData} cgmMeasurements={cgmMeasurements} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={{boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
            <Typography variant="h3" color={theme.palette.basic[1000]}>
              Demographics
            </Typography>
            <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
              Personal information
            </Typography>
            <Grid container spacing={2} mt={0.5} mb={1}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Subject number
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {clinicalTrial.id}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Time zone
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  null
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5} mb={1}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Gender
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {gender}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Age
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {moment().diff(birthDate, 'years')} years
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5} mb={4}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Height
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {heightInCm} cm
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Weight
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {weightInKg} kg
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={4}>
              Info about diabetes
            </Typography>
            <Grid container spacing={2} mt={0.5} mb={4}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Date of diagnosis
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {changeFormateDate(diabetesFoundDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Date of Insulin Therapy start
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {changeFormateDate(therapyStartDate)}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={4}>
              Studying center info
            </Typography>
            <Grid container spacing={2} mt={0.5} mb={2}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Center
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  null
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Protocol acronym
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  null
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={{minHeight: '662px', boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
            <Typography variant="h3" color={theme.palette.basic[1000]}>
              Devices
            </Typography>
            <Grid container spacing={2} mt={0.5} mb={1}>
              {devices?.map(device => {
                return (
                  <Grid container item xs={12} key={device.deviceType + device.id}>
                    <Grid item xs={12}>
                      <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
                        {device.deviceType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} mb={1}>
                      <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                        Model
                      </Typography>
                      <Typography variant="h4" color={theme.palette.basic[1000]}>
                        {device.model}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} mb={1}>
                      <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                        Serial Number
                      </Typography>
                      <Typography variant="h4" color={theme.palette.basic[1000]}>
                        {device.serialNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} mb={1}>
                      <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                        Alias
                      </Typography>
                      <Typography variant="h4" color={theme.palette.basic[1000]}>
                        {device.alias}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} mb={1}>
                      <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                        Last Updated
                      </Typography>
                      <Typography variant="h4" color={theme.palette.basic[1000]}>
                        {changeFormateDate(device.lastUpdatedTimestamp)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
