import {Navigate} from 'react-router-dom';

import {CircularProgress} from '../components';
import {userService} from '../api';

type AuthGuardProps = {
  children: React.ReactElement;
};

export const AuthGuard = ({children}: AuthGuardProps) => {
  const userId = localStorage.getItem('userId');

  if (!userId) {
    return <Navigate to="/auth/login" />;
  }

  const {isLoading} = userService.useGetUserByIdQuery({params: {id: userId}});

  /* TODO: This is a temporary solution. We have to discuss and choose some solution */
  if (isLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }

  return children;
};
