import {useParams} from 'react-router-dom';

import {EventCardLayout} from '../../../components';
import {participantsService} from '../../../api';
import type {EventResponse} from '../../../api/service-types';

export type EventCardProps = {
  event: EventResponse;
};

export const EventCard = ({event}: EventCardProps): JSX.Element => {
  const {id} = useParams();
  if (!id) throw new Error('clinical trial id is undefined, unhandled error');

  const {data: participantsByClinicalTrialId} = participantsService.useGetParticipantsByClinicalTrialIdQuery({
    params: {id},
  });
  if (!participantsByClinicalTrialId) throw new Error('participantsByClinicalTrialId is undefined, unhandled error');

  const participant = participantsByClinicalTrialId.find(participant => participant.id === event.participantId);
  if (!participant) throw new Error('participant is undefined, unhandled error');

  return <EventCardLayout event={event} participant={participant} />;
};
