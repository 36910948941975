import {Select} from '@mui/material';

import {BasicSelectProps} from './types';
import {StyledFormControl, StyledOutlinedInput, StyledMenuItem} from './styles';

export const BasicSelect = ({
  background,
  color,
  colorModeSelected,
  label,
  defaultValue,
  value,
  menuItems,
  handleChange,
}: BasicSelectProps): JSX.Element => {
  return (
    <StyledFormControl background={background}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        defaultValue={defaultValue}
        value={value}
        label={label}
        input={<StyledOutlinedInput sx={{color: color}} colorModeSelected={colorModeSelected} />}
        onChange={handleChange}>
        {menuItems?.map(menuItem => {
          const {id, value, label} = menuItem;
          return (
            <StyledMenuItem key={id} value={value}>
              {label}
            </StyledMenuItem>
          );
        })}
      </Select>
    </StyledFormControl>
  );
};
