import {styled} from '@mui/material/styles';

export const StyledPasswordIndicatorContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
}));

export const StyledDot = styled('div')(({theme}) => ({
  height: '6px',
  width: '6px',
  borderRadius: '6px',
  backgroundColor: theme.palette.basic[200],
}));
