import {Icon} from '../../components/icon';
import {Typography, useTheme} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

import {StyledContainer, StyledIconContainer} from './styles';

export const Logo = () => {
  const theme = useTheme();
  const userId = localStorage.getItem('userId');

  return (
    <StyledContainer component={RouterLink} to={userId ? '/dashboard' : '/auth/login'} underline="none">
      <StyledIconContainer>
        <Icon icon="logo" size={24} color={theme.palette.basic[0]} />
      </StyledIconContainer>
      <Typography variant="h4">DigiCARE</Typography>
    </StyledContainer>
  );
};
