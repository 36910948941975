import {InsulinTrends, PaletteOptions, PaletteColorOptions} from '@mui/material/styles';

const BASIC: PaletteColorOptions = {
  0: '#FFFFFF',
  50: '#F4F4F4',
  100: '#EEEEEE',
  200: '#DCDEDE',
  300: '#CBCDCD',
  500: '#1C2121',
  600: '#939595',
  800: '#636969',
  900: '#2B2C2E',
  1000: '#1C2121',
};

const PRIMARY: PaletteColorOptions = {
  50: '#F0F9F8',
  500: '#059984',
  600: '#69C1B5',
  800: '#44B2A2',
  1000: '#059984',
};

const ERROR: PaletteColorOptions = {
  800: '#F4133F',
  500: '#D00029',
  1000: '#D00029',
};

const INSULIN_TRENDS: InsulinTrends = {
  veryHigh: {
    200: '#FEE6D4',
    1000: '#FC8329',
  },
  high: {
    200: '#FEEFD3',
    1000: '#FCAE23',
  },
  normal: {
    200: '#0FAB39',
    1000: '#CFEED7',
  },
  low: {
    200: '#DF0707',
    1000: '#F9CDCD',
  },
  veryLow: {
    200: '#960000',
    1000: '#ECD6D6',
  },
};

const CUSTOM_COLORS = {
  darkBlue: '#2F5ED7',
  pacificBlue: '#019FC1',
  green: '#0FAB39',
  darkGrey: '#BDC1C6',
  lightGrey: '#9099AA',
  selectionGrey: '#EBEFF2',
  selectionBlack: '#535B62',
};

export const palette: PaletteOptions = {
  common: {black: '#000', white: '#fff'},
  basic: BASIC,
  primaryCustom: PRIMARY,
  errorCustom: ERROR,
  insulinTrends: INSULIN_TRENDS,
  customColors: CUSTOM_COLORS,
};
