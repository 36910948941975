import {Breakpoint, useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type Query = 'up' | 'down' | 'between' | 'only';

type UseResponsiveProps =
  | {query: Exclude<Query, 'between'>; start: Breakpoint; end?: never}
  | {query: Extract<Query, 'between'>; start: Breakpoint; end: Breakpoint};

export const useResponsive = ({query, start, end}: UseResponsiveProps): boolean => {
  const theme = useTheme();

  if (query === 'up') {
    const mediaUp = useMediaQuery(theme.breakpoints.up(start));
    return mediaUp;
  }

  if (query === 'down') {
    const mediaDown = useMediaQuery(theme.breakpoints.down(start));
    return mediaDown;
  }

  if (query === 'between') {
    const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));
    return mediaBetween;
  }

  const mediaOnly = useMediaQuery(theme.breakpoints.only(start));
  return mediaOnly;
};
