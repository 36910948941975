import {CardMedia} from '@mui/material';
import {styled} from '@mui/material/styles';

export const StyledRoot = styled('div')(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export const StyledSection = styled('div')(({theme}) => ({
  padding: theme.spacing(1, 1),
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background?.default,
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: '24px',
}));
