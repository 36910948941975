import {Box} from '@mui/material';
import React from 'react';

import {StyledRootScrollbar, StyledScrollbar} from './styles';

type ScrollbarProps = {
  sx: object;
  children: React.ReactNode;
};

export const Scrollbar = ({children, sx, ...other}: ScrollbarProps) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return (
      <Box sx={{overflowX: 'auto', ...sx}} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
};
