import {useState} from 'react';

export const useSearch = (initialValue = '') => {
  const [searchValue, setSearchValue] = useState(initialValue);

  const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onResetSearchValue = () => {
    setSearchValue('');
  };

  return {
    searchValue,
    onChangeSearchValue,
    onResetSearchValue,
  };
};
