import {createApi, BaseQueryFn} from '@reduxjs/toolkit/query/react';

import {httpsUrl} from '../../constants';
import {baseQuery} from '../../helpers';
import type {ClinicalTrial} from '../service-types';
import type {IdParams} from '../query-types';

const clinicalTrialsApiBuilder = (baseQuery: BaseQueryFn) =>
  createApi({
    reducerPath: 'clinical-trials-service',
    baseQuery,
    endpoints: builder => ({
      getClinicalTrials: builder.query<ClinicalTrial[], void>({
        query() {
          return {
            url: '/clinical-trials',
            method: 'GET',
          };
        },
      }),
      getActiveClinicalTrials: builder.query<ClinicalTrial[], void>({
        query() {
          return {
            url: '/clinical-trials/all-active',
            method: 'GET',
          };
        },
      }),
      getClinicalTrialById: builder.query<ClinicalTrial, {params: IdParams}>({
        query({params}) {
          return {
            url: `/clinical-trials/${params.id}`,
            method: 'GET',
          };
        },
      }),
    }),
  });

export const clinicalTrialsService = clinicalTrialsApiBuilder(baseQuery(httpsUrl));
