import {useTheme} from '@mui/material';

import {Icon} from '../icon';
import {Color} from '../../interfaces/styles';
import type {MeasurementTrend} from '../../api/service-types';
import {StyledContainer} from './styles';

type Size = 12 | 16 | 24;

export type InsulinTrendIconProps = {
  trend: MeasurementTrend;
  size?: Size;
};

const widthMap: Record<Size, string> = {
  12: '20px',
  16: '26px',
  24: '40px',
};

const heightMap: Record<Size, string> = {
  12: '20px',
  16: '26px',
  24: '40px',
};

export const InsulinTrendIcon = ({trend, size = 24}: InsulinTrendIconProps): JSX.Element => {
  const theme = useTheme();
  const iconMap: Record<MeasurementTrend, JSX.Element> = {
    '-1': <Icon icon="arrow-down" size={size} color={theme.palette.insulinTrends.veryLow[200]} />,
    0: <Icon icon="arrow-right" size={size} color={theme.palette.insulinTrends.normal[200]} />,
    1: <Icon icon="arrow-up" size={size} color={theme.palette.insulinTrends.high[1000]} />,
  };

  const iconBackgroundMap: Record<MeasurementTrend, Color> = {
    '-1': theme.palette.insulinTrends.veryLow[1000],
    0: theme.palette.insulinTrends.normal[1000],
    1: theme.palette.insulinTrends.high[200],
  };

  return (
    <StyledContainer width={widthMap[size]} height={heightMap[size]} background={iconBackgroundMap[trend]}>
      {iconMap[trend]}
    </StyledContainer>
  );
};
