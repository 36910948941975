import {useState} from 'react';
import Dropzone, {FileRejection} from 'react-dropzone';
import {useTheme} from '@mui/material';

import {Avatar, Icon} from '../../../components';

import {StyledContainer, StyledDropzoneContainer, StyledIconEditWrapper, StyledIconResetWrapper} from './styles';

type LoadProfilePictureProps = {
  initials: string;
};

export const LoadProfilePicture = ({initials}: LoadProfilePictureProps): JSX.Element => {
  const theme = useTheme();

  const [src, setSrc] = useState<string | null>(null);

  const onLoad = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length) return;
    const file = acceptedFiles[0];
    setSrc(URL.createObjectURL(file));
    /* TODO: add a request to load profile picture */
    console.log('file', file);
  };

  const onReset = async () => {
    setSrc(null);
    /* TODO: add a request to remove profile picture */
  };

  return (
    <StyledContainer>
      <Dropzone
        maxSize={5e6}
        accept={{
          'image/png': ['.png'],
          'image/jpeg': ['.jpeg', '.jpg'],
        }}
        onDrop={(acceptedFiles, fileRejections) => onLoad(acceptedFiles, fileRejections)}>
        {({getRootProps, getInputProps, open}) => (
          <StyledDropzoneContainer {...getRootProps()} onClick={open}>
            <input {...getInputProps()} />

            <Avatar src={src} initials={initials} alt="Profile photo" size="xl" typographyVariant="h2" />

            <StyledIconEditWrapper>
              <Icon icon="edit" size={16} color={theme.palette.primaryCustom[800]} />
            </StyledIconEditWrapper>
          </StyledDropzoneContainer>
        )}
      </Dropzone>

      {src && (
        <StyledIconResetWrapper onClick={onReset}>
          <Icon icon="close" size={16} color={theme.palette.primaryCustom[800]} />
        </StyledIconResetWrapper>
      )}
    </StyledContainer>
  );
};
