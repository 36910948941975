import {BoxProps, IconButton, IconButtonProps, MenuItem, alpha} from '@mui/material';
import {styled} from '@mui/material/styles';

type OpenProps = {
  open: boolean;
};

type IsSelected = {
  isSelected: boolean;
};

export const StyledAssignButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'open',
})<IconButtonProps & OpenProps>(({theme, open}) => ({
  position: 'relative',
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '30px',
  border: '1px solid',
  borderColor: theme.palette.primaryCustom[800],
  backgroundColor: open ? theme.palette.primaryCustom[50] : theme.palette.common.white,
  color: theme.palette.primaryCustom[800],

  '&:disabled': {
    borderColor: theme.palette.basic[300],
    color: theme.palette.basic[300],
  },

  '&:hover': {
    backgroundColor: theme.palette.primaryCustom[50],
    ':after': {
      content: '""',
      position: 'absolute',
      width: '30px',
      height: '30px',
      borderRadius: '30px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
  },
}));

export const StyledPopoverContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '272px',
  padding: '8px 8px 4px',
  gap: '4px',
}));

export const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  display: 'flex',
  borderRadius: '8px',
  padding: '8px 10px',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.basic[800],

  '&:hover': {
    backgroundColor: theme.palette.primaryCustom[50],
  },
}));

export const StyledParticipant = styled('div', {
  shouldForwardProp: prop => prop !== 'isSelected',
})<BoxProps & IsSelected>(({theme, isSelected}) => ({
  display: 'flex',
  width: '210px',
  gap: '6px',
  alignItems: 'center',
  color: isSelected ? theme.palette.primaryCustom[800] : undefined,
}));
