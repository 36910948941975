import {styled} from '@mui/material/styles';

import {DASHBOARD} from '../../../config';

const APP_BAR_DESKTOP = DASHBOARD.APP_BAR_DESKTOP;
const DEVICE_EVENTS = DASHBOARD.DEVICE_EVENTS;

export const StyledContainer = styled('div')(({theme}) => ({
  position: 'fixed',
  top: APP_BAR_DESKTOP,
  right: 0,
  width: DEVICE_EVENTS,
  height: `calc(100vh - ${APP_BAR_DESKTOP}px)`,
  overflow: 'hidden',
  display: 'flex',
  backgroundColor: theme.palette.basic[100],
}));

export const StyledContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1px',
}));

export const StyledHeader = styled('div')(({theme}) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  gap: '4px',
}));
