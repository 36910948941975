import {Tab, useTheme} from '@mui/material';
import {TabsProps} from './types';
import {StyledTabsBox, StyledTabs} from './styles';

export const ScrollableTabs = ({value, tabItems, handleClick, handleChange}: TabsProps): JSX.Element => {
  const theme = useTheme();

  return (
    <StyledTabsBox sx={{maxWidth: {xs: 320, sm: 680}, ml: '10px', zIndex: '9'}}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{
          style: {background: theme.palette.primaryCustom[800]},
        }}
        aria-label="scrollable auto tabs example">
        {tabItems?.map((tabItem, index) => <Tab key={index} label={tabItem} onClick={handleClick} />)}
      </StyledTabs>
    </StyledTabsBox>
  );
};
