import {useTheme, Typography} from '@mui/material';
import {CircularProgress} from '../../components';
import {EventsTableFilter} from '../../sections';
import {participantsService, eventsService} from '../../api';
import {pollingInterval} from '../../constants';
import {StyledContainer} from './styles';
import {NoData} from '../../components/data-tables/utils';

export const EventsPage = () => {
  const theme = useTheme();
  const {
    data: participants,
    isLoading: areParticipantsLoading,
    error: participantsError,
    refetch: refetchParticipants,
  } = participantsService.useGetParticipantsQuery(undefined, {pollingInterval});

  const {data: eventsStatusFilters} = eventsService.useGetEventsStatusFiltersQuery(undefined, {pollingInterval});

  const onRefetch = async () => {
    refetchParticipants();
  };

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (areParticipantsLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (participantsError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  if (!participants) {
    throw new Error('participants or events are undefined, unhandled state');
  }

  return (
    <StyledContainer>
      <Typography variant="h2" color={theme.palette.basic[1000]}>
        Events
      </Typography>
      {eventsStatusFilters ? (
        //@ts-expect-error: events need to be refactored
        <EventsTableFilter participants={participants} eventStatus={eventsStatusFilters} />
      ) : (
        <NoData />
      )}
    </StyledContainer>
  );
};
