import {TextField, TextFieldProps, Typography, useTheme} from '@mui/material';

import {Icon} from '../icon';
import {StyledContainer, StyledErrorContainer} from './styles';

export type InputFieldProps = TextFieldProps & {
  errorMessage?: string;
};

export const InputField = ({label, errorMessage, ...restProps}: InputFieldProps) => {
  const theme = useTheme();

  const displayedLabel = typeof label === 'string' ? <Typography variant="captionSmall">{label}</Typography> : label;

  return (
    <StyledContainer>
      {displayedLabel}
      <TextField {...restProps} error={!!errorMessage} />
      {errorMessage && (
        <StyledErrorContainer>
          <Icon icon="email" size={16} color={theme.palette.errorCustom[1000]} />
          <Typography variant="bodySmall" color={theme.palette.errorCustom[1000]}>
            {errorMessage}
          </Typography>
        </StyledErrorContainer>
      )}
    </StyledContainer>
  );
};
