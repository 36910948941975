type GetFromNameProps = {
  firstName?: string;
  lastName?: string;
};

type GetFromCommenterNameProps = {
  commenterFirstName?: string;
  commenterLastName?: string;
};

const getValidValue = (value: string | undefined) => value ?? '';

export const getInitials = ({firstName, lastName}: GetFromNameProps) =>
  getValidValue(firstName?.[0]) + getValidValue(lastName?.[0]);

export const getCommenterInitials = ({commenterFirstName, commenterLastName}: GetFromCommenterNameProps) =>
  getValidValue(commenterFirstName?.[0]) + getValidValue(commenterLastName?.[0]);

export const getFullName = ({firstName, lastName}: GetFromNameProps) =>
  `${getValidValue(firstName)} ${getValidValue(lastName)}`.trim();
