import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from '../src/store';

import 'simplebar-react/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
/* 
  INFO: using StrictMode causes incorrect operation react-toastify
*/
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
