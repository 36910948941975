import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {InputAdornment, Stack} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';

import {Icon, InputField, InlineLink} from '../../../components';
import {ForgotPasswordFormData} from '../../../interfaces/auth';
import {getValidationSchema} from '../../../utils';
import {httpsUrl} from '../../../constants';
import axios from 'axios';

type ForgotPasswordFormProps = {
  sentEmailSuccessfully: () => void;
};

export const SendResetPasswordEmail = (email: string): void => {
  axios.get(`${httpsUrl}/authentication/reset-password/send-email/${email}`).then(function (response) {
    console.info(response);
  });
};

export const ForgotPasswordForm = ({sentEmailSuccessfully}: ForgotPasswordFormProps) => {
  const {forgotPasswordValidationSchema} = getValidationSchema();

  const {control, handleSubmit} = useForm<ForgotPasswordFormData>({
    resolver: yupResolver(forgotPasswordValidationSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmitHandler = (e: {email: string}) => {
    const emailToReceive = e.email as string;

    SendResetPasswordEmail(emailToReceive);
    sentEmailSuccessfully();
  };

  return (
    <>
      <Stack spacing={3} sx={{my: 2}}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: true,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <InputField
              name="email"
              label="Email"
              placeholder="john.doe@example.com"
              errorMessage={error?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon="email" size={17} />
                  </InputAdornment>
                ),
              }}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit(onSubmitHandler)}
        disableFocusRipple>
        Send instructions
      </LoadingButton>
      <Stack spacing={3} sx={{my: 2}} alignItems="center">
        <InlineLink to="/auth/login">I remember my password</InlineLink>
      </Stack>
    </>
  );
};
