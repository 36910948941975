import {Typography} from '@mui/material';
import {LinkProps} from 'react-router-dom';

import {StyledLink} from './styles';

type InlineLinkProps = LinkProps & {
  disabled?: boolean;
};

export const InlineLink = ({children, disabled, ...props}: InlineLinkProps): JSX.Element => {
  return (
    <StyledLink
      {...props}
      onClick={
        disabled
          ? e => {
              e.preventDefault();
            }
          : undefined
      }
      disabled={disabled}>
      <Typography variant="captionBig">{children}</Typography>
    </StyledLink>
  );
};
