import {Link, LinkProps} from '@mui/material';
import {styled} from '@mui/material/styles';
import {LinkProps as RouterLinkProps} from 'react-router-dom';

export const StyledContainer = styled(Link)<LinkProps & Pick<RouterLinkProps, 'to'>>(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  color: theme.palette.basic[1000],
}));

export const StyledIconContainer = styled('div')(({theme}) => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: theme.palette.primaryCustom[800],
}));
