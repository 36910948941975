import {Card} from '@mui/material';
import {styled} from '@mui/material/styles';

type StyledStatusLineProps = {
  priority: number;
};

type StyledStatusCircleProps = StyledStatusLineProps;

export const StyledCard = styled(Card)(({theme}) => ({
  position: 'relative',
  borderRadius: 0,
  padding: theme.spacing(2),
}));

export const StyledStatusLine = styled('div', {
  shouldForwardProp: prop => prop !== 'priority',
})<StyledStatusLineProps>(({theme, priority}) => {
  const backgroundColorMap: any = {
    3: theme.palette.errorCustom[800],
    2: theme.palette.basic[300],
    1: theme.palette.basic[300],
  };

  return {
    position: 'absolute',
    height: '75%',
    width: '3px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    backgroundColor: backgroundColorMap[priority],
    top: '50%',
    left: '0',
    transform: 'translate(0px, -50%)',
  };
});

export const StyledStatusCircle = styled('div', {
  shouldForwardProp: prop => prop !== 'priority',
})<StyledStatusCircleProps>(({theme, priority}) => {
  const backgroundColorMap: any = {
    3: theme.palette.errorCustom[800],
    2: theme.palette.basic[300],
    1: theme.palette.basic[300],
  };

  return {
    position: 'absolute',
    height: '6px',
    width: '6px',
    borderRadius: '6px',
    backgroundColor: backgroundColorMap[priority],
    top: '50%',
    left: '-9px',
    transform: 'translate(-50%, -50%)',
  };
});

export const StyledCardContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '12px',
  gap: '10px',
}));

export const StyledEventInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledInfo = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledActions = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
