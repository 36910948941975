import {SyntheticEvent, useState} from 'react';

export const usePopover = <T extends Element>() => {
  const [open, setOpen] = useState<(EventTarget & T) | null>(null);

  const handleOpen = (event: SyntheticEvent<T, Event>) => {
    setOpen(event?.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return {open, setOpen, handleOpen, handleClose};
};
