import {styled} from '@mui/material/styles';

import {Color} from '../../interfaces/styles';

type StyledContainerProps = {
  marginval: string;
  background: Color;
};

export const StyledContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'background',
})<StyledContainerProps>(({theme, marginval, background}) => ({
  width: 'fit-content',
  padding: '3px 12px',
  borderRadius: '100px',
  color: theme.palette.basic[0],
  fontSize: '10px',
  fontWeight: 600,
  lineHeight: '18px',
  textTransform: 'uppercase',
  marginLeft: marginval,
  marginRight: marginval,
  background,
}));
