import dayjs from 'dayjs';

import {TagStatus} from '../components';
import type {ClinicalTrial} from '../api/service-types';

export const getClinicalTrialStatus = (clinicalTrial: ClinicalTrial): TagStatus => {
  const currentDate = dayjs();
  const endDateTime = dayjs(clinicalTrial.endDateTime);
  const diff = endDateTime.diff(currentDate);
  if (diff >= 0) return 'active';
  return 'ended';
};
