import {AppBar, IconButton, MenuItem, Toolbar} from '@mui/material';
import {styled} from '@mui/material/styles';

import {DASHBOARD} from '../../../config';
import {bgBlur} from '../../../helpers';

const NAV_WIDTH = DASHBOARD.NAV_WIDTH;
const HEADER_MOBILE = DASHBOARD.HEADER_MOBILE;
const HEADER_DESKTOP = DASHBOARD.HEADER_DESKTOP;

export const StyledRoot = styled(AppBar)(({theme}) => ({
  ...bgBlur({color: theme.palette.background.default}),
  boxShadow: theme.customShadows.down,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

export const StyledToolbar = styled(Toolbar)(({theme}) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}));

export const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  backgroundColor: theme.palette.primaryCustom[800],
}));
