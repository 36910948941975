import {useState} from 'react';
import dayjs from 'dayjs';
import {Divider, Popover, Typography, useTheme} from '@mui/material';

import {Icon, CircularProgress} from '../../components';
import {usePopover} from '../../hooks';
import type {EventResponse} from '../../api/service-types';
import {eventsService} from '../../api';
import {DATE_TIME} from '../../constants';

import {
  StyledActions,
  StyledCancelButton,
  StyledCommentButton,
  StyledPopoverContent,
  StyledSendButton,
  StyledTextField,
} from './styles';

type CommentProps = {
  event: EventResponse;
};

export const Comment = ({event}: CommentProps): JSX.Element => {
  const theme = useTheme();

  const {
    data: eventCommentsByThreadId,
    isLoading: areEventCommentsByThreadIdLoading,
    error: eventCommentsByThreadIdError,
    refetch: refetchEventCommentsByThreadId,
  } = eventsService.useGetEventCommentsByThreadIdQuery({
    params: {id: event.id},
  });
  const [createEventComment] = eventsService.useCreateEventCommentMutation();

  const [eventComment, setEventComment] = useState('');
  const {open, handleOpen, handleClose} = usePopover<HTMLButtonElement>();

  const onChangeEventComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEventComment(e.target.value);
  };

  const closePopover = () => {
    handleClose();
    setEventComment('');
  };

  const onCreateEventComment = async () => {
    const userId = localStorage.getItem('userId');
    if (!event.id || !userId) throw new Error('commentThreadId or userId are undefined, unhandled error');

    const creationDateTime = dayjs().format(DATE_TIME.YYYY_MM_DD_T_HH_mm);

    try {
      await createEventComment({
        body: {
          threadId: event.id,
          commenterUserId: Number(userId),
          text: eventComment,
          creationDateTime,
        },
      }).unwrap();

      handleClose();
    } catch (e) {
      handleClose();
      // TODO: add error handler
      console.log('ERROR - onCreateEventComment', e);
    }
  };

  const onRefetch = async () => {
    refetchEventCommentsByThreadId();
  };

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (areEventCommentsByThreadIdLoading) {
    return <CircularProgress size={40} />;
  }

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (eventCommentsByThreadIdError) {
    return (
      <div>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  if (!eventCommentsByThreadId) {
    throw new Error('eventCommentsByThreadId is undefined, unhandled state');
  }

  const commentsNumber = eventCommentsByThreadId.length;
  const hasComments = commentsNumber > 0;
  const isSubmitDisabled = eventComment.length === 0;

  return (
    <>
      <StyledCommentButton onClick={handleOpen} open={Boolean(open)} disabled={hasComments}>
        <Icon icon="comment" size="16px" color={Boolean(open) ? undefined : theme.palette.basic[300]} />
        <Typography variant="captionSmall">{hasComments ? commentsNumber : 'Comment'}</Typography>
      </StyledCommentButton>
      <Popover
        sx={{zIndex: 9999}}
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}>
        <StyledPopoverContent>
          <StyledTextField
            value={eventComment}
            onChange={onChangeEventComment}
            placeholder="Add a comment"
            multiline
            variant="standard"
            minRows={2}
            maxRows={5}
          />
        </StyledPopoverContent>
        <Divider />
        <StyledActions>
          <StyledCancelButton onClick={closePopover}>
            <Typography variant="captionBig">Cancel</Typography>
          </StyledCancelButton>
          <StyledSendButton type="button" onClick={onCreateEventComment} disabled={isSubmitDisabled}>
            <Icon icon="add-comment" size="16px" color={theme.palette.common.white} />
          </StyledSendButton>
        </StyledActions>
      </Popover>
    </>
  );
};
