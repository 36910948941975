import {styled} from '@mui/material/styles';
import {Select, MenuItem, Pagination} from '@mui/material';

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 16px',
}));

export const StyledRowsPerPage = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledSelect = styled(Select)(({theme}) => ({
  '&.MuiInputBase-root': {
    height: '32px',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '20px',
    borderRadius: '8px',
    background: theme.palette.basic[50],
    '& .MuiSelect-select': {
      padding: '0px 35px 0px 12px',
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-icon': {
      right: '6px',
      top: '9px',
    },
    '&:hover': {
      background: theme.palette.primaryCustom[50],
    },
  },
  '&.Mui-focused': {
    color: theme.palette.primaryCustom[1000],
    background: theme.palette.primaryCustom[50],
    '.MuiSelect-icon': {
      color: theme.palette.primaryCustom[1000],
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    padding: '8px 111px 8px 4px',
    color: theme.palette.basic[800],
    '&:hover': {
      background: theme.palette.basic[0],
    },
    '&:focus': {
      background: theme.palette.basic[0],
    },
  },
  '&.Mui-selected': {
    background: theme.palette.basic[0],
    color: theme.palette.primaryCustom[500],
    fontWeight: 600,
  },
}));

export const StyledPagination = styled(Pagination)(({theme}) => ({
  '& .MuiButtonBase-root': {
    borderRadius: '8px',
    margin: '4px',
    padding: '0px',
    '&:hover': {
      color: theme.palette.basic[1000],
      background: theme.palette.basic[100],
    },
    '&:focus': {
      color: theme.palette.basic[1000],
      background: theme.palette.basic[300],
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  '& .MuiPaginationItem-page': {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.basic[800],
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    color: theme.palette.basic[1000],
    background: theme.palette.basic[0],
    '&:hover': {
      color: theme.palette.basic[1000],
      background: theme.palette.basic[100],
    },
    '&:focus': {
      color: theme.palette.basic[1000],
      background: theme.palette.basic[300],
    },
  },
  '& .MuiPaginationItem-previousNext': {
    color: theme.palette.basic[600],
  },
}));
