import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {InputAdornment, Stack} from '@mui/material';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {Icon, InputField, InputPasswordField} from '../../../components';
import {LoginFormData} from '../../../interfaces/auth';
import {getValidationSchema} from '../../../utils';
import {authService} from '../../../api';

export const LoginForm = () => {
  const navigate = useNavigate();

  const {loginValidationSchema} = getValidationSchema();

  const [login] = authService.useLoginMutation();

  const {control, handleSubmit} = useForm<LoginFormData>({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmitHandler: SubmitHandler<LoginFormData> = async data => {
    try {
      const response = await login({
        body: {
          loginEmail: data.email,
          password: data.password,
        },
      }).unwrap();

      localStorage.setItem('userId', response.userId);
      localStorage.setItem('token', response.token);

      navigate('/dashboard');
    } catch (e) {
      console.log('login: ', e);
    }
  };

  return (
    <>
      <Stack spacing={3} sx={{my: 2}}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: true,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <InputField
              name="email"
              label="Email"
              placeholder="john.doe@example.com"
              errorMessage={error?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon="email" size={17} />
                  </InputAdornment>
                ),
              }}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name="password"
          rules={{
            required: true,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <InputPasswordField
              name="password"
              label="Password"
              placeholder="Password"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              errorMessage={error?.message}
              withForgot
            />
          )}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit(onSubmitHandler)}
        disableFocusRipple>
        Login
      </LoadingButton>
    </>
  );
};
