import {ListItemButton, ListItemButtonProps, ListItemIcon} from '@mui/material';
import {styled} from '@mui/material/styles';
import {LinkProps as RouterLinkProps} from 'react-router-dom';

import {Icon, IconProps} from '../../../components';

type StyledNavItemProps = ListItemButtonProps &
  Pick<RouterLinkProps, 'to'> & {
    hasChildren: boolean;
    nestingLevel: number;
  };

export const StyledNavItem = styled(props => <ListItemButton disableGutters {...props} />, {
  shouldForwardProp: prop => prop !== 'hasChildren' && prop !== 'nestingLevel',
})<StyledNavItemProps>(({theme, hasChildren, nestingLevel}) => ({
  ...(nestingLevel === 0 ? theme.typography.bodyBigSemibold : theme.typography.bodySmall),
  ...(nestingLevel === 1 && hasChildren ? theme.typography.uppercaseMedium : {}),
  color: nestingLevel === 0 ? theme.palette.basic[1000] : theme.palette.basic[800],
  height: '40px',
  paddingLeft: '12px',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  gap: '8px',

  '&.active': !hasChildren
    ? {
        color: theme.palette.basic[1000],
        backgroundColor: theme.palette.primaryCustom[50],
        fontWeight: theme.typography.fontWeightMedium,
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: 0,
          width: '3px',
          height: '20px',
          transform: 'translate(0px, -50%)',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          backgroundColor: theme.palette.primaryCustom[800],
        },
        '& svg': {
          color: theme.palette.primaryCustom[800],
        },
      }
    : {},
}));

export const StyledArrowIcon = styled(ListItemIcon)({
  minWidth: 14,
  height: 14,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledNavItemIcon = styled(ListItemIcon)({
  minWidth: 22,
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type StyledIconProps = IconProps & {
  opened: boolean;
};

export const StyledIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'opened',
})<StyledIconProps>(({opened}) => ({
  transform: `rotate(${opened ? '0' : '270deg'})`,
}));
