import {CircularProgress, EventCardLayout} from '../../../components';
import {participantsService} from '../../../api';
import type {EventResponse} from '../../../api/service-types';

export type ToastCardProps = {
  event: EventResponse;
};

export const ToastCard = ({event}: ToastCardProps): JSX.Element => {
  const {
    data: participants,
    isLoading: areParticipantsLoading,
    error: participantsError,
    refetch: refetchParticipants,
  } = participantsService.useGetParticipantsQuery();

  const onRefetch = async () => {
    refetchParticipants();
  };

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (areParticipantsLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (participantsError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  if (!participants) {
    throw new Error('participants are undefined, unhandled state');
  }

  const participant = participants.find(participant => participant.id === event.participantId);
  if (!participant) throw new Error('participant is undefined, unhandled error');

  return <EventCardLayout event={event} participant={participant} withEventDateTime={false} />;
};
