import {Theme} from '@mui/material';

import {Button} from './Button';
import {Card} from './Card';
import {Checkbox} from './Checkbox';
import {Input} from './Input';
import {Link} from './Link';
import {Popover} from './Popover';
import {Typography} from './Typography';

export const componentsOverrides = (theme: Theme): Partial<Theme['components']> => {
  return Object.assign(
    {},
    Button(theme),
    Card(theme),
    Checkbox(theme),
    Input(theme),
    Link(theme),
    Popover(theme),
    Typography(theme),
  );
};
