import {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {Box} from '@mui/material';
import {ScrollableTabs, CircularProgress} from '../../components';
import {GeneralSection, ParticipantDetailsSection} from '../../sections';
import {participantsService, StaticData, DynamicData} from '../../api';
import {StyledContainer} from './styles';

export const ParticipantPage = () => {
  const generalRef = useRef<HTMLDivElement>(null);
  const participantDetailsRef = useRef<HTMLDivElement>(null);
  const advancedReadingRef = useRef<HTMLDivElement>(null);

  const {id} = useParams();

  const [value, setValue] = useState<number>(0);
  const [initiallyRendered, setInitiallyRendered] = useState<boolean>(false);
  const [staticData, setStaticData] = useState<StaticData>();
  const [dynamicData, setDynamicData] = useState<DynamicData>();

  const {
    data: overallScreenData,
    isLoading: isOverallScreenDataLoading,
    error: overallScreenDataError,
    refetch: refetchOverallScreenData,
  } = participantsService.useGetOverallScreenDataQuery({
    params: {id: id},
  });

  const onRefetch = async () => {
    refetchOverallScreenData();
  };
  const scrollToSection = (index: number) => {
    const yOffsetgeneral = -150; // Adjust this value according to the height of your fixed header/navbar
    const yOffset = -60; // Adjust this value according to the height of your fixed header/navbar
    const yParticipantDetailsOffset = -150;
    switch (index) {
      case 0:
        if (generalRef.current) {
          const y = generalRef.current.getBoundingClientRect().top + window.scrollY + yOffsetgeneral;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
        break;
      case 1:
        if (participantDetailsRef.current) {
          const y =
            participantDetailsRef.current.getBoundingClientRect().top + window.scrollY + yParticipantDetailsOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
        break;
      case 2:
        if (advancedReadingRef.current) {
          const y = advancedReadingRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    scrollToSection(value);
  }, [value]);

  useEffect(() => {
    setStaticData(overallScreenData?.participant);
    setDynamicData(overallScreenData?.dynamicData);
    setInitiallyRendered(true);
  }, [overallScreenData]);

  useEffect(() => {
    if (initiallyRendered) {
      const interval = setInterval(() => {
        //refetchDynamicScreenData();
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [initiallyRendered]);

  if (isOverallScreenDataLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (overallScreenDataError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  if (!overallScreenData) {
    throw new Error('Overall screen data is undefined, unhandled state');
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const participantTabs: string[] = ['General', 'Participant details', 'Advanced reading'];
  return (
    <>
      <ScrollableTabs value={value} tabItems={participantTabs} handleChange={handleChange} />
      <Box sx={{display: 'flex', flexDirection: 'row'}}></Box>
      <StyledContainer>
        {staticData && dynamicData && (
          <GeneralSection generalRef={generalRef} staticData={staticData} dynamicData={dynamicData} />
        )}
        {staticData && dynamicData && (
          <ParticipantDetailsSection
            participantDetailsRef={participantDetailsRef}
            staticData={staticData}
            dynamicData={dynamicData}
          />
        )}
        {/* {staticData && (
          <AdvancedReadingSection advancedReadingRef={advancedReadingRef} participant={staticData?.participant} />
        )} */}
      </StyledContainer>
    </>
  );
};
