import {styled} from '@mui/material/styles';

import {DASHBOARD} from '../../config';
import {IsMobile} from '../../utils/IsMobile';

const APP_BAR_MOBILE = DASHBOARD.APP_BAR_MOBILE;
const APP_BAR_DESKTOP = DASHBOARD.APP_BAR_DESKTOP;
const DEVICE_EVENTS = DASHBOARD.DEVICE_EVENTS;

type StyledMainProps = {
  areEventsShown: boolean;
};

export const StyledRoot = styled('div')(({theme}) => ({
  position: 'relative',
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  background: theme.palette.basic[50],
}));

export const StyledMain = styled('div', {
  shouldForwardProp: prop => prop !== 'areEventsShown',
})<StyledMainProps>(({theme, areEventsShown = false}) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  backgroundColor: theme.palette.basic[50],
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  paddingRight: !IsMobile() && areEventsShown ? DEVICE_EVENTS : 0,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: areEventsShown ? DEVICE_EVENTS : 0,
  },
}));
