import {Theme} from '@mui/material/styles';

export const Checkbox = (theme: Theme): Theme['components'] => {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: theme.palette.primaryCustom[800],
          },
        },
      },
    },
  };
};
