import {Theme} from '@mui/material/styles';

export const Popover = (theme: Theme): Theme['components'] => {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.intensiveAround,
          borderRadius: '8px',
        },
      },
    },
  };
};
