import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 8px',
  gap: '12px',
  height: '100%',
}));

export const StyledEventsContainer = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  background: theme.palette.basic[0],
  borderRadius: '12px',
  boxShadow: theme.customShadows.around,
}));

export const StyledTablesContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 10px',
  gap: '12px',
  height: '100%',
  background: theme.palette.basic[0],
  borderRadius: '12px',
  boxShadow: theme.customShadows.around,
}));
