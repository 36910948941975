import {Box, styled, Tab, Tabs, Typography, useTheme} from '@mui/material';
import {StyledContainer} from '../../pages/events/styles';
import PropTypes from 'prop-types';
import React, {Suspense} from 'react';
import {CircularProgress} from '../../components';
import {participantsService} from '../../api';
import {pollingInterval} from '../../constants';
import {DataTableFilters} from '../../components/data-tables/DataTableFilters';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props: {[x: string]: any; children: any; value: any; index: any}) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{p: 3}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
const LowerCaseTab = styled(Tab)({
  textTransform: 'capitalize',
});

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const DataTablesPage = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const {
    data: participants,
    isLoading: areParticipantsLoading,
    error: participantsError,
    refetch: refetchParticipants,
  } = participantsService.useGetParticipantsQuery(undefined, {pollingInterval});

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };
  const onRefetch = async () => {
    refetchParticipants();
  };

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (areParticipantsLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }
  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (participantsError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  return (
    <StyledContainer>
      <Typography variant="h2" color={theme.palette.basic[1000]}>
        Data Tables
      </Typography>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <LowerCaseTab label="Glucose Readings" {...a11yProps(0)} />
          <LowerCaseTab label="Glucometer Measurements" {...a11yProps(1)} />
          <LowerCaseTab label="Insulin Computed" {...a11yProps(2)} />
          <LowerCaseTab label="Controller State Change" {...a11yProps(3)} />
          <LowerCaseTab label="Controller Event" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <Suspense fallback={<CircularProgress size={40} />}>
        <CustomTabPanel value={value} index={0}>
          {participants && <DataTableFilters participants={participants} type={'cgmMeasurements'} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {participants && <DataTableFilters participants={participants} type={'glucometerMeasurements'} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {participants && <DataTableFilters participants={participants} type={'insulinComputed'} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {participants && <DataTableFilters participants={participants} type={'controllerStatedChange'} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          {participants && <DataTableFilters participants={participants} type={'controllerEventChange'} />}
        </CustomTabPanel>
      </Suspense>
    </StyledContainer>
  );
};
