import {faBell} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography, useTheme} from '@mui/material';

export const NoEventsYet = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Typography
      variant="h4"
      component="span"
      color={theme.palette.basic[1000]}
      display={'flex'}
      textAlign="center"
      justifyContent={'center'}
      padding={10}>
      <div>
        <FontAwesomeIcon icon={faBell} fontSize="2rem" />
        <br />
        No new events yet!
      </div>
    </Typography>
  );
};
