import {styled} from '@mui/material/styles';
import {Outlet} from 'react-router-dom';

import {Logo} from '../../components';

const StyledHeader = styled('header')(({theme}) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: 'fit-content',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

export const SimpleLayout = () => {
  return (
    <>
      <StyledHeader>
        <Logo />
      </StyledHeader>

      <Outlet />
    </>
  );
};
