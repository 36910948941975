import {ListItemText} from '@mui/material';
import {NavLink as RouterLink} from 'react-router-dom';

import {useBoolean} from '../../../hooks';
import {NavItemType} from '../../../interfaces/navigation';

import {StyledArrowIcon, StyledIcon, StyledNavItem, StyledNavItemIcon} from './styles';

type NavItemProps = {
  nestingLevel?: number;
  item: NavItemType;
};

export const NavItem = ({nestingLevel = 0, item: {title, path, icon, info, children = []}}: NavItemProps) => {
  const {value: opened, toggle} = useBoolean(false);
  const hasChildren = children.length > 0;

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={(hasChildren ? children[0].path : path) || ''}
        hasChildren={hasChildren}
        nestingLevel={nestingLevel}
        onClick={toggle}>
        <StyledArrowIcon>
          {hasChildren && icon && <StyledIcon icon="down-solid" size="14px" opened={opened} />}
        </StyledArrowIcon>
        <StyledNavItemIcon>
          {icon ? icon : hasChildren && <StyledIcon icon="down-solid" size="14px" opened={opened} />}
        </StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </StyledNavItem>
      {opened && children.map(item => <NavItem key={item.title} item={item} nestingLevel={nestingLevel + 1} />)}
    </>
  );
};
