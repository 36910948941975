import {Typography, useTheme} from '@mui/material';

import {Icon} from '../../../components';

import {StyledDot, StyledPasswordIndicatorContainer} from './styles';

interface PasswordIndicatorProps {
  title: string;
  isError: boolean;
}

export const PasswordIndicator = ({title, isError}: PasswordIndicatorProps) => {
  const theme = useTheme();

  return (
    <StyledPasswordIndicatorContainer>
      {isError ? <StyledDot /> : <Icon icon="check-rounded" size={12} color={theme.palette.primaryCustom[800]} />}
      <Typography variant="bodySmall" color={isError ? theme.palette.basic[200] : theme.palette.basic[1000]}>
        {title}
      </Typography>
    </StyledPasswordIndicatorContainer>
  );
};
