import * as yup from 'yup';

import {fieldsErrorMessages} from '../constants';
import {ValidationSchemas} from '../interfaces/validation';
import moment from 'moment-timezone';

export const getValidationSchema = (): ValidationSchemas => {
  const {NOT_VALID, PASSWORDS_DO_NOT_MATCH, REQUIRED} = fieldsErrorMessages;

  return {
    loginValidationSchema: yup.object({
      email: yup.string().required(REQUIRED).matches(RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,}$'), NOT_VALID),
      password: yup
        .string()
        .required(REQUIRED)
        .min(8, NOT_VALID)
        .matches(RegExp('^[^ЁёА-я]+$'), NOT_VALID)
        .matches(RegExp('^(?=.*[A-Z]).*$'), NOT_VALID)
        .matches(RegExp('^(?=.*[a-z]).*$'), NOT_VALID)
        .matches(RegExp('^(?=.*\\d).*$'), NOT_VALID)
        .matches(
          RegExp(
            '^(?=.*[!@#$%^&*()\\[\\]{};:<>?+,.\\-\\/\\\\=|~])([a-zA-Z0-9!@#$%^&*()\\[\\]{};:<>?+,.\\-\\/\\\\=|~]*)$',
          ),
          NOT_VALID,
        )
        .matches(RegExp('^\\S+$'), NOT_VALID),
    }),
    forgotPasswordValidationSchema: yup.object({
      email: yup.string().required(REQUIRED).matches(RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,}$'), NOT_VALID),
    }),
    createNewPasswordValidationSchema: yup.object({
      new: yup
        .string()
        .required(REQUIRED)
        .min(8, NOT_VALID)
        .matches(RegExp('^[^ЁёА-я]+$'), {name: 'onlyLatin', message: NOT_VALID})
        .matches(RegExp('^(?=.*[A-Z]).*$'), {name: 'oneUppercase', message: NOT_VALID})
        .matches(RegExp('^(?=.*[a-z]).*$'), {name: 'oneLowercase', message: NOT_VALID})
        .matches(RegExp('^(?=.*\\d).*$'), {name: 'oneNumber', message: NOT_VALID})
        .matches(
          RegExp(
            '^(?=.*[!@#$%^&*()\\[\\]{};:<>?+,.\\-\\/\\\\=|~])([a-zA-Z0-9!@#$%^&*()\\[\\]{};:<>?+,.\\-\\/\\\\=|~]*)$',
          ),
          {name: 'oneSpecialCharacter', message: NOT_VALID},
        )
        .matches(RegExp('^\\S+$'), {name: 'noSpaces', message: NOT_VALID}),
      repeat: yup
        .string()
        .required(REQUIRED)
        .oneOf([yup.ref('new')], PASSWORDS_DO_NOT_MATCH),
    }),
  };
};

export const {timeZone} = Intl.DateTimeFormat().resolvedOptions();

export const DateTimeAgo = (lastUpdate: string) => moment(new Date(lastUpdate)).tz(timeZone).fromNow();

export const changeFormateDateUTC = (oldDate: string) => {
  return moment(oldDate).tz(timeZone).format('DD/MM/YYYY HH:mm:ss');
};
export const changeFormateDate = (oldDate: string) => {
  return moment(oldDate).format('DD/MM/YYYY HH:mm:ss');
};
export const changeFormateTime = (time: string) => {
  return moment(time).format('HH:mm');
};

/*
function isBoolean(value: any): boolean {
  return typeof value === 'boolean';
}
*/
