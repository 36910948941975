import {useNavigate} from 'react-router-dom';
import {useErrorBoundary} from 'react-error-boundary';
import {Button, Typography, useTheme} from '@mui/material';

import {StyledContainer} from './styles';

export const ErrorFallback = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {resetBoundary} = useErrorBoundary();

  const reset = () => {
    resetBoundary();
    navigate('/');
  };

  return (
    <StyledContainer>
      <div>
        <Typography variant="h2" color={theme.palette.primaryCustom[1000]} textAlign="center">
          Whoops!
        </Typography>
        <Typography variant="h6" color={theme.palette.basic[1000]} textAlign="center">
          Error occurred on client
        </Typography>
      </div>
      <Button onClick={reset} size="large" variant="contained" disableFocusRipple>
        Go to Home
      </Button>
    </StyledContainer>
  );
};
