import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 8px',
  gap: '12px',
}));

export const StyledTitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledTitleContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
}));

export const StyledContent = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
}));
