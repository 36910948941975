import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  useTheme,
} from '@mui/material';

type CircularProgressProps = Omit<MuiCircularProgressProps, 'color'>;

export const CircularProgress = (props: CircularProgressProps): JSX.Element => {
  const theme = useTheme();

  return <MuiCircularProgress sx={{color: theme.palette.primaryCustom[1000]}} {...props} />;
};
