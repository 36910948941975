import {useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';

export const IsMobile = () => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(isMobile);
  }, []);

  return mobile;
};
