import {EventsStatusFilters, Participant, TableResponse, userService} from '../../../api';
import {NoData} from '../../../components/data-tables/utils';
import {ENDPOINT_TIME_OPTIONS, httpsUrl} from '../../../constants';
import {StyledTablesContainer} from '../../../pages/events/styles';
import {TableHead, TableHeadCell} from '../../../components/table-head';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {AssignUser, DialogBox} from '../../../components';
import CircleIcon from '@mui/icons-material/Circle';
import {addSpaceBeforeUppercaseFn} from '../../../helpers/commonReusableFns';
import {StyledBodyTableRow, StyledBodyTableCell, StyledCgmContainer} from './styles';
import {useAppDispatch} from '../../../store';
import CommentIcon from '@mui/icons-material/Comment';
import {setEventDetailsDialogOpen} from '../../../store/reducers/commentsActionSlice';
import {convertStatusFiltersObjToArr, EventTypes, EventTypesFormatString} from './utils';
import {changeFormateDate, changeFormateDateUTC} from '../../../utils';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  SelectChangeEvent,
  Table,
  TableBody,
  Typography,
  useTheme,
  TableContainer,
  Grid,
} from '@mui/material';
import {StatusComponent} from '../../../components/status/Status';

export type EventsTableFiltersProps = {
  participants: Participant[];
  eventStatus: EventsStatusFilters;
};

export const cells: TableHeadCell[] = [
  {label: 'ID', width: '10%', align: 'center'},
  {label: 'Event type', width: '15%'},
  {label: 'Participant ID', width: '15%'},
  {label: 'Status', width: '5%', align: 'center'},
  {label: 'Assignee', width: '5%', align: 'center'},
  {label: 'No. of comments', width: '10%', align: 'center'},
  {label: 'Event date & time', width: '15%'},
  {label: 'Local Event date & time', width: '15%'},
];

export const EventsTableFilter = ({participants, eventStatus}: EventsTableFiltersProps) => {
  const [responseData, setResponseData] = useState<TableResponse>();
  const [currentStart, setCurrentStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [nextStart, setNextStart] = useState(limit);
  const [timeFrame, setTimeFrame] = useState<string>(ENDPOINT_TIME_OPTIONS.WEEK);
  const [selectedParticipant, setSelectedParticipant] = useState<string>('All');
  const [selectedStatus, setSelectedStatus] = useState<string>('All');
  const [selectedType, setSelectedType] = useState<string>('All');
  const [assignedUserId, setAssignedUserId] = useState<string>('All');
  const [page, setPage] = useState(0);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [openEventDetailsDialog, setOpenEventDetailsDialog] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();

  const {data: users} = userService.useGetUsersQuery();

  const GetEvents = () => {
    if (selectedType !== 'All')
      return axios
        .get(
          `${httpsUrl}/event-notifications?start=${currentStart}&limit=${limit}&timeframe=${timeFrame}&participantId=${selectedParticipant}&status=${selectedStatus}&eventType=${selectedType}&assignedUserId=${assignedUserId}`,
        )
        .then(function (response) {
          setResponseData(response.data);
          console.log(response.data);
        });

    return axios
      .get(
        `${httpsUrl}/event-notifications?start=${currentStart}&limit=${limit}&timeframe=${timeFrame}&participantId=${selectedParticipant}&status=${selectedStatus}&assignedUserId=${assignedUserId}`,
      )
      .then(function (response) {
        setResponseData(response.data);
        console.log(response.data);
      });
  };

  useEffect(() => {
    GetEvents();
  }, [
    selectedParticipant,
    selectedStatus,
    timeFrame,
    limit,
    currentStart,
    triggerRefetch,
    selectedType,
    assignedUserId,
  ]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage < page) {
      setNextStart(currentStart);
      setCurrentStart(currentStart - limit);
      setPage(newPage);
    } else {
      setCurrentStart(nextStart);
      setNextStart(nextStart + limit);
      setPage(newPage);
    }
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(parseInt(event.target.value));
    setNextStart(parseInt(event.target.value));
    setCurrentStart(0);
    setPage(0);
  };
  const handleChangeTimeFrame = (event: SelectChangeEvent) => {
    setTimeFrame(event?.target?.value as string);
  };
  const handleChangeParticipants = (event: SelectChangeEvent) => {
    setSelectedParticipant(event?.target?.value as string);
  };
  const handleChangeEventStatus = (event: SelectChangeEvent) => {
    setSelectedStatus(event?.target?.value as string);
  };
  const handleChangeType = (event: SelectChangeEvent) => {
    setSelectedType(event?.target?.value as string);
  };
  const handleChangeAssignedUserId = (event: SelectChangeEvent) => {
    setAssignedUserId(event?.target?.value as string);
  };
  const handleEventDialogOpen = () => {
    setOpenEventDetailsDialog(true);
    dispatch(setEventDetailsDialogOpen(true));
    setTriggerRefetch(!triggerRefetch);
  };
  const handleEventDialogClose = () => {
    setOpenEventDetailsDialog(false);
    dispatch(setEventDetailsDialogOpen(false));
    setTriggerRefetch(!triggerRefetch);
  };
  const handleEventDialogRefetch = () => {
    dispatch(setEventDetailsDialogOpen(false));
    setTriggerRefetch(!triggerRefetch);
  };

  // @ts-expect-error: totalCount exists but ts do not recognize
  const isDataAvailable = responseData && responseData.totalCount != 0;
  // @ts-expect-error: totalCount exists but ts do not recognize
  const totalEvents = responseData ? responseData.totalCount : 0;
  const uniqueStatuses = convertStatusFiltersObjToArr(eventStatus);

  return (
    <StyledTablesContainer>
      <Box>
        <Grid container item xs={12} spacing={2} mt={2} mb={3}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Time Frame</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={timeFrame}
                label="Time Frame"
                onChange={handleChangeTimeFrame}>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.HOUR}>Hour</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_HOURS}>3 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.SIX_HOURS}>6 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_DAYS}>3 days</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.DAY}>24 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.WEEK}>Week</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.TWO_WEEKS}>2 weeks</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.MONTH}>Month</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.ALL_TIME}>All time</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="participants-select-label">Participants</InputLabel>
              <Select
                labelId="participants-select-label"
                id="participants-simple-select"
                value={selectedParticipant}
                label="Participants"
                onChange={handleChangeParticipants}>
                <MenuItem value="-">All</MenuItem>
                {Object.keys(participants).map((participant, i) => (
                  <MenuItem key={i} value={participants[participant as any].id}>
                    {participants[participant as any].id} - {participants[participant as any].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="eventStatus-select-label">Status</InputLabel>
              <Select
                labelId="eventStatus-select-label"
                id="eventStatus-simple-select"
                value={selectedStatus}
                label="Status"
                onChange={handleChangeEventStatus}>
                <MenuItem value="-">All</MenuItem>
                <MenuItem value={'new'}>New</MenuItem>
                <MenuItem value={'in-progress'}>In Progress</MenuItem>
                <MenuItem value={'completed'}>Completed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="event-type-select-label">Type</InputLabel>
              <Select
                labelId="event-type-select-label"
                id="event-type-simple-select"
                value={selectedType}
                label="Type"
                onChange={handleChangeType}>
                <MenuItem value="All">All</MenuItem>
                {Object.keys(EventTypes).map((type, i) => (
                  <MenuItem key={i} value={EventTypes[type as any]}>
                    {EventTypesFormatString(EventTypes[type as any])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="assigned-user-id-select">Assigned User Id</InputLabel>
              <Select
                labelId="assigned-user-id-label"
                id="assigned-user-id-select"
                value={assignedUserId}
                label="Assigned User Id"
                onChange={handleChangeAssignedUserId}>
                <MenuItem value="All">All</MenuItem>
                {users &&
                  users.map((user, i) => (
                    <MenuItem key={i} value={user.id}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {isDataAvailable ? (
        <TableContainer>
          <Table>
            <TableHead cells={cells} />
            <TableBody>
              {responseData.data?.map(event => {
                const eventID = event.id;
                const eventTypeWholeString = event.originatingEvent.type;
                const eventTypeParts = eventTypeWholeString.split('.');
                const eventTypeUnformatted = eventTypeParts[eventTypeParts.length - 1];
                const eventType = eventTypeUnformatted && addSpaceBeforeUppercaseFn(eventTypeUnformatted);
                const eventPriority = event.priority;
                const participantId = event.participantId;
                const noOfComments = event.commentCount;
                const eventLocalDateTime = event.originatingEvent.eventLocalDateTime;
                const eventUTCInstant = event.originatingEvent.eventUTCInstant;
                console.log('eventType', eventType);
                return (
                  <StyledBodyTableRow key={eventID} hover>
                    <StyledBodyTableCell align="center">
                      <Typography variant="bodyBigSemibold" color={theme.palette.basic[1000]}>
                        <CircleIcon
                          sx={{
                            height: '8px',
                            width: '8px',
                            mt: '5px',
                            mr: '5px',
                            color: eventPriority === 3 ? theme.palette.errorCustom[800] : theme.palette.basic[300],
                          }}
                        />
                        {eventID}
                      </Typography>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography
                          variant="captionBig"
                          color={theme.palette.primaryCustom[1000]}
                          sx={{'&:hover': {cursor: 'pointer', color: theme.palette.primaryCustom[800]}}}
                          onClick={() => {
                            handleEventDialogOpen();
                            setSelectedEvent(event);
                          }}>
                          {eventType}
                        </Typography>
                      </Box>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell align="center">{participantId}</StyledBodyTableCell>
                    <StyledBodyTableCell align="center">
                      <StatusComponent
                        onClose={handleEventDialogClose}
                        selectedEvent={event}
                        uniqueStatuses={uniqueStatuses}
                        onRefetch={handleEventDialogRefetch}
                      />
                    </StyledBodyTableCell>
                    <StyledBodyTableCell align="center">
                      <StyledCgmContainer>
                        <AssignUser event={event} onRefetch={handleEventDialogRefetch} />
                      </StyledCgmContainer>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell align="center">
                      <Typography variant="bodySmallBold" color={theme.palette.basic[800]}>
                        <CommentIcon
                          sx={{
                            height: '16px',
                            width: '16px',
                            color: theme.palette.basic[300],
                            mr: '6px',
                            marginBottom: '-4px',
                          }}
                        />
                        {noOfComments}
                      </Typography>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                        {eventUTCInstant ? changeFormateDateUTC(eventUTCInstant) : 'No event date available!'}
                      </Typography>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                        {eventLocalDateTime ? changeFormateDate(eventLocalDateTime) : 'No event date available!'}
                      </Typography>
                    </StyledBodyTableCell>
                  </StyledBodyTableRow>
                );
              })}
              <DialogBox
                selectedEvent={selectedEvent}
                eventsStatusFilters={eventStatus}
                uniqueStatuses={uniqueStatuses}
                dialogOpen={openEventDetailsDialog}
                onClose={handleEventDialogClose}
                onRefetch={handleEventDialogRefetch}
              />
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoData />
      )}
      <TablePagination
        component="div"
        count={isDataAvailable ? totalEvents : 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledTablesContainer>
  );
};
