import {useState} from 'react';
import {debounce} from 'lodash';
import {Button, InputAdornment, Typography, useTheme} from '@mui/material';
import {Unstable_Popup as BasePopup} from '@mui/base/Unstable_Popup';

import {InputField, Icon} from '../../../components';
import {LoadProfilePicture, SendResetPasswordEmail} from '../../../sections';
import {getInitials} from '../../../helpers';
import {userService, wsService} from '../../../api';

import {
  StyledContainer,
  StyledImageContainer,
  StyledImageInfoContainer,
  StyledContent,
  StyledInputContainer,
} from './styles';
import {resetServices, resetSlices} from '../../../store/actions';
import {useAppDispatch} from '../../../store';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';

const PopupBody = styled('div')(
  ({theme}) => `
  width: max-content;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434D5B' : '#DAE2ED'};
  background-color: ${theme.palette.mode === 'dark' ? '#1C2025' : '#fff'};
  box-shadow: ${theme.palette.mode === 'dark' ? `0px 4px 8px rgb(0 0 0 / 0.7)` : `0px 4px 8px rgb(0 0 0 / 0.1)`};
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  z-index: 1;
`,
);

function timeout(delay: number) {
  return new Promise(res => setTimeout(res, delay));
}

export const Profile = () => {
  const theme = useTheme();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [updateUser] = userService.useUpdateUserMutation();

  const userId = localStorage.getItem('userId');
  if (!userId) throw new Error('userId is undefined, unhandled error');
  const {data: user} = userService.useGetUserByIdQuery({params: {id: userId}});
  if (!user) throw new Error('user is undefined, unhandled error');

  const open = Boolean(anchor);
  const id = open ? 'simple-popup' : undefined;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resetLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
  };

  const logout = () => {
    wsService.disconnect();
    resetLocalStorage();
    dispatch(resetServices());
    dispatch(resetSlices());
    navigate('/auth/login');
  };

  const {firstName, lastName, loginEmail} = user;
  const initials = getInitials({firstName, lastName});

  const onSubmit = debounce(async () => {
    await updateUser({
      body: {
        id: user.id,
        firstName: firstName,
        lastName: lastName,
        loginEmail: user.loginEmail,
      },
    });
  }, 2000);

  const onChangeFullName = () => {
    onSubmit();
  };

  const ChangePasswordrButton = styled(Button)(({theme}) => ({
    color: theme.palette.primaryCustom[800],
    borderColor: theme.palette.primaryCustom[800],
    '&:hover': {
      backgroundColor: theme.palette.primaryCustom[800],
      color: theme.palette.basic[200],
    },
  }));

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    SendResetPasswordEmail(loginEmail);
    setAnchor(anchor ? null : event.currentTarget);
    await timeout(5000);
    logout();
  };

  return (
    <StyledContainer>
      <StyledContent>
        <Typography variant="h4" color={theme.palette.basic[1000]}>
          Profile picture
        </Typography>
        <StyledImageContainer>
          <LoadProfilePicture initials={initials} />
          <StyledImageInfoContainer>
            <Typography variant="bodySmall" color={theme.palette.basic[800]}>
              Format: .png, .jpg, .jpeg.
            </Typography>
            <Typography variant="bodySmall" color={theme.palette.basic[800]}>
              Max size: 5 MB.
            </Typography>
            <Typography variant="bodySmall" color={theme.palette.basic[800]}>
              Recommended dimensions: 200x200 px.
            </Typography>
          </StyledImageInfoContainer>
        </StyledImageContainer>
      </StyledContent>
      <StyledContent>
        <Typography variant="h4" color={theme.palette.basic[1000]}>
          Personal information
        </Typography>
        <StyledInputContainer>
          <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
            <InputField
              value={firstName}
              onChange={onChangeFullName}
              label="First name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon="user" size={20} color={theme.palette.basic[300]} />
                  </InputAdornment>
                ),
              }}
            />
            <InputField
              value={lastName}
              onChange={onChangeFullName}
              label="Last name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon="user" size={20} color={theme.palette.basic[300]} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <InputField
            defaultValue={loginEmail}
            label="Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="email" size={20} color={theme.palette.basic[300]} />
                </InputAdornment>
              ),
            }}
            disabled
          />
        </StyledInputContainer>
      </StyledContent>
      <StyledContent>
        <Typography variant="h4" color={theme.palette.basic[1000]}>
          Password
        </Typography>
        <ChangePasswordrButton variant="outlined" onClick={handleClick}>
          Change password
        </ChangePasswordrButton>
        <BasePopup id={id} open={open} anchor={anchor}>
          <PopupBody>
            You will be logout.
            <br /> Please check your email!
          </PopupBody>
        </BasePopup>
      </StyledContent>
    </StyledContainer>
  );
};
