import {TableCell, TableRow, IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')(({theme}) => ({
  background: theme.palette.basic[0],
  boxShadow: theme.customShadows.around,
  borderRadius: '8px',
}));

export const SearchStyledContainer = styled('div')(() => ({
  display: 'flex',
  padding: '14px',
  gap: '8px',
}));

export const StyledCgmContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
}));

export const StyledBodyTableRow = styled(TableRow)(({theme}) => ({
  borderBottom: `1px solid ${theme.palette.basic[100]}`,
  '&.MuiTableRow-hover': {
    '&:hover': {
      background: theme.palette.primaryCustom[50],
    },
  },
}));

export const StyledBodyTableCell = styled(TableCell)(() => ({
  padding: '16px',
  border: 'none',
}));

export const StyledIconButton = styled(IconButton)(({theme}) => ({
  color: theme.palette.primaryCustom[500],
}));
