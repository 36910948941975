import {styled} from '@mui/material/styles';
import {IconButton} from '@mui/material';
import {IsMobile} from '../../../utils/IsMobile';

export const StyledContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  gap: '14px',
  boxShadow: theme.customShadows.around,
  background: theme.palette.basic[0],
  borderRadius: '8px',
  maxWidth: '277px',
  width: !IsMobile() ? '300px' : '100%',
  minWidth: '300px',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

export const StyledContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '12px 0px',
}));

export const StyledLastUpdate = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  padding: '3px 0px',
}));

export const StyledTimeValue = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
}));

export const StyledValueContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}));

export const StyledValue = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '2px',
}));

export const StyledOperationMode = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
}));

export const StyledIconButton = styled(IconButton)(() => ({
  padding: '0px',
}));
