import {Typography, useTheme} from '@mui/material';
import {TableDataTypeProps} from '../../components';
import {faFolderOpen} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const NoData = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Typography
      variant="h3"
      component="span"
      color={theme.palette.basic[1000]}
      display={'flex'}
      textAlign="center"
      justifyContent={'center'}
      padding={10}>
      <div>
        <FontAwesomeIcon icon={faFolderOpen} fontSize="2rem" />
        <br />
        No data found!
      </div>
    </Typography>
  );
};

export const EndpointFormatedType = (type: TableDataTypeProps): string => {
  switch (type) {
    case 'cgmMeasurements':
      return 'cgm-measurements';
      break;

    case 'controllerStatedChange':
      return 'controller-state-changed';
      break;

    case 'controllerEventChange':
      return 'controller-event';
      break;

    case 'glucometerMeasurements':
      return 'glucometer-measurements';
      break;

    case 'insulinComputed':
      return 'insulin-computed';
      break;
  }
};

export function isBoolean(val: boolean) {
  return val === false || val === true;
}
