import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const StyledErrorContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));
